@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,600&subset=latin-ext';
.dashboard .dashboard-panel, .btn, .form-group .c-error:not(.no-transition) {
  transition: all .4s ease-in-out; }

a, .btn-link {
  color: #ff6d00; }
  a:focus, .btn-link:focus, a:active, .btn-link:active {
    color: #ff6d00; }
  a:hover, .btn-link:hover {
    color: #ff6d00;
    text-decoration: underline; }
  a.dark, .dark.btn-link {
    color: #4a5456; }
  a.underline, .underline.btn-link {
    text-decoration: underline; }
    a.underline:hover, .underline.btn-link:hover {
      text-decoration: none; }
  a:disabled, .btn-link:disabled {
    color: #A3A3A3;
    text-decoration: none; }
    a:disabled:hover, .btn-link:disabled:hover {
      color: #A3A3A3;
      text-decoration: none; }

.arrow-after-down {
  padding-right: 2.5rem;
  position: relative; }
  .arrow-after-down:after {
    position: absolute;
    right: 0.7rem;
    top: 50%;
    content: " ";
    margin-top: -0.5rem;
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #ff6d00; }
  .arrow-after-down .open:after {
    border-width: 0 2px 2px 0;
    margin-top: -0.1rem; }

.arrow-after-up {
  padding-right: 2.5rem;
  position: relative; }
  .arrow-after-up:after {
    position: absolute;
    right: 0.7rem;
    top: 50%;
    content: " ";
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(-135deg);
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #ff6d00; }
  .arrow-after-up .open:after {
    border-width: 0 2px 2px 0;
    margin-top: -0.1rem; }

.inline-elements {
  align-items: center; }

.inline-elements img {
  position: relative;
  height: 23px;
  width: 23px; }

.furniture-new-icon {
  margin-bottom: -4px;
  margin-top: -5px; }

.dashboard .dashboard-panel {
  background: #fafafa;
  border: 1px solid #e8e8e8;
  padding: 2rem 2rem;
  height: 10rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  display: block;
  color: #4a5456;
  text-decoration: none;
  cursor: pointer; }
  .dashboard .dashboard-panel:hover {
    border-color: #ff6d00; }
  .dashboard .dashboard-panel h4 {
    padding: 0;
    margin: 0 0 0.5rem 0;
    font-size: 1.9rem;
    font-weight: bold; }
  .dashboard .dashboard-panel > span {
    display: block;
    font-size: 1.2rem;
    color: #A3A3A3; }

.dashboard .home-news {
  margin-top: 2rem;
  margin-bottom: 2rem; }
  .dashboard .home-news article {
    color: white;
    height: 163px;
    margin-top: 15px; }
    .dashboard .home-news article p {
      font-size: 1.2rem;
      color: #A3A3A3; }
    .dashboard .home-news article .default-container {
      padding: 5px 10px 10px; }
      .dashboard .home-news article .default-container h4 {
        font-size: 1.3rem; }
      .dashboard .home-news article .default-container time {
        color: white; }
      .dashboard .home-news article .default-container p {
        font-size: 1.2rem;
        color: white; }
    .dashboard .home-news article .article-background {
      width: 360px;
      height: 163px;
      position: absolute;
      z-index: 1; }
    .dashboard .home-news article .default-bg {
      z-index: -1;
      background-color: #00018a; }
  .dashboard .home-news .see-all {
    margin-top: 1rem; }
  .dashboard .home-news h2 {
    text-transform: uppercase;
    font-size: 1.7rem;
    font-weight: 300;
    margin: 1rem 0;
    padding: 0 0 1rem 0;
    border-bottom: 1px solid #e8e8e8; }
  .dashboard .home-news h4 {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 0.7rem 0 0.2rem 0; }
  .dashboard .home-news time {
    display: block;
    color: #A3A3A3;
    font-size: 1.2rem;
    margin-bottom: 1rem; }

form[name='form'] .control-label {
  font-size: 1.5rem; }

form[name='form'] .recover-label {
  font-size: 1.9rem;
  margin-top: 5px; }

form[name='form'] .recover-box {
  height: 60px;
  margin-top: 20px;
  margin-bottom: 20px; }

sg-login-form .remember-password-link,
form[name='recover_pass_form'] .remember-password-link {
  line-height: 3rem;
  padding-top: 1rem; }

sg-login-form a,
form[name='recover_pass_form'] a {
  color: #ff6d00; }

sg-login-form a:hover,
form[name='recover_pass_form'] a:hover {
  color: #ff9f00; }

sg-login-form .form-group .help-block,
form[name='recover_pass_form'] .form-group .help-block {
  display: none; }

sg-login-form .form-group.has-error .help-block,
form[name='recover_pass_form'] .form-group.has-error .help-block {
  display: block; }

sg-login-form .alert,
form[name='recover_pass_form'] .alert {
  margin-bottom: 0; }

.loginPage {
  margin-top: 1.5rem;
  border-top: 1px solid #e8e8e8; }
  .loginPage .page-header {
    margin: 7rem 0 3rem; }
    .loginPage .page-header h1 {
      font-weight: normal; }
  .loginPage .buttons {
    margin-top: 2rem; }
  .loginPage .panel {
    padding-top: 6rem;
    padding-bottom: 6rem;
    border-radius: 0;
    background: #fafafa;
    border: 1px solid #e8e8e8;
    box-shadow: none;
    margin-bottom: 6rem; }
  .loginPage .login-links li {
    list-style: none;
    margin: 6rem 0 6rem 3rem; }
    .loginPage .login-links li a {
      color: #4a5456;
      position: relative;
      padding-left: 70px; }
      .loginPage .login-links li a:before {
        margin-top: -25px;
        position: absolute;
        width: 50px;
        height: 50px;
        left: 0;
        top: 50%;
        content: " "; }
      .loginPage .login-links li a.stalgast:before {
        background: url("img/stalgast_icon.png") no-repeat;
        height: 51px;
        width: 51px; }
      .loginPage .login-links li a.catalog:before {
        background: url("img/catalog_icon.png") no-repeat;
        height: 51px;
        width: 51px; }
      .loginPage .login-links li a.contact:before {
        background: url("img/contact_icon.png") no-repeat;
        height: 51px;
        width: 51px; }
  .loginPage .well {
    padding: 3.5rem; }

.agreement-readmore {
  padding-left: 2rem; }

.articles-module h1 {
  margin-bottom: 0.5rem; }

.articles-module time {
  display: block;
  color: #A3A3A3;
  font-size: 1.2rem; }

.articles-module .content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.account-module h5 {
  font-weight: bold; }

.account-module .info-block {
  margin-bottom: 3rem; }
  .account-module .info-block h5:after {
    content: ':'; }
  .account-module .info-block ul {
    padding-left: 0;
    list-style: none; }
  .account-module .info-block address {
    margin-bottom: 1rem; }
  .account-module .info-block .recipient span:not(:last-child):after {
    content: ", "; }

.account-module .table th.column-building {
  width: 80px; }

.account-module .table th.column-postcode {
  width: 110px; }

.account-module .table th.column-city {
  width: 180px; }

.account-module .roles {
  background: #fafafa;
  padding: 1rem 2rem; }
  .account-module .roles h2 {
    font-size: 1.8rem;
    font-weight: bold; }
  .account-module .roles h3 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem; }
  .account-module .roles .role {
    padding-bottom: 1rem;
    font-size: 1.3rem; }

.account-module .mailer-allow {
  border-top: 1px solid #e8e8e8;
  padding-top: 2rem;
  margin-top: 1rem; }

.blocks-module .content {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.complaint-module .form-group .date-container {
  display: flex;
  align-items: center;
  margin: 5px 0; }
  .complaint-module .form-group .date-container label {
    margin-right: 10px; }

.complaint-module .complaint-item .content {
  padding-bottom: 20px; }
  .complaint-module .complaint-item .content table {
    border-collapse: separate;
    border-spacing: 0 0.5em;
    margin-bottom: 20px; }
    .complaint-module .complaint-item .content table td {
      padding-right: 50px; }
      .complaint-module .complaint-item .content table td:first-of-type {
        font-weight: 800; }
      .complaint-module .complaint-item .content table td span {
        margin-right: 10px; }
  .complaint-module .complaint-item .content .contact p {
    margin-bottom: 5px; }
  .complaint-module .complaint-item .content .contact_mail {
    display: inline-block;
    margin-bottom: 5px; }

.complaint-module .complaint-item .btn {
  float: right;
  margin-bottom: 20px; }

.cart-module header.page-header h1 {
  display: inline-block; }

.cart-module header.page-header button.btn {
  vertical-align: super;
  margin: 5px 0;
  margin-left: 5px;
  padding: 5px 10px;
  font-size: 13px; }

.modal-body h3 {
  margin: 0.3rem 0 2rem;
  font-size: 18px;
  font-weight: bold; }

.modal-body .import-actions {
  text-align: center;
  margin: 1rem 0; }

.modal-md {
  width: 700px !important; }

sg-footer {
  display: block;
  margin-top: 1.5rem;
  width: 100%;
  background: #4a5456;
  color: #fff;
  z-index: 75; }
  sg-footer footer {
    margin-top: -9.5rem;
    height: 8rem;
    line-height: 8rem; }
    sg-footer footer a {
      margin-left: 1rem; }

.attach-dropbox {
  position: relative;
  min-height: 100px;
  height: 100%;
  text-align: center;
  border: 4px dashed #DDD;
  background: #F8F8F8;
  cursor: pointer; }

.attachment-table {
  width: 100%; }
  .attachment-table tr {
    border: 1px solid #e8e8e8; }
    .attachment-table tr th {
      background: #FAFAFA;
      padding: 8px; }
      .attachment-table tr th span {
        margin: 0 0 5px 0;
        font-size: 1.5rem;
        font-weight: 800; }
    .attachment-table tr td {
      padding: 8px; }

.product-component {
  border: 1px solid #e8e8e8;
  border-top: none;
  margin: 0 0 30px; }
  .product-component .ng-hide.ng-hide-animate {
    display: none !important; }
  .product-component .step1,
  .product-component .step2,
  .product-component .step3,
  .product-component .step4 {
    border-top: 1px solid #e8e8e8; }
    .product-component .step1 > div:not(:last-of-type),
    .product-component .step2 > div:not(:last-of-type),
    .product-component .step3 > div:not(:last-of-type),
    .product-component .step4 > div:not(:last-of-type) {
      padding: 10px; }
    .product-component .step1 .warranty,
    .product-component .step2 .warranty,
    .product-component .step3 .warranty,
    .product-component .step4 .warranty {
      border-top: 1px solid #e8e8e8;
      margin-top: 10px; }
      .product-component .step1 .warranty b,
      .product-component .step2 .warranty b,
      .product-component .step3 .warranty b,
      .product-component .step4 .warranty b {
        margin-left: 10px; }
    .product-component .step1 .step-header,
    .product-component .step2 .step-header,
    .product-component .step3 .step-header,
    .product-component .step4 .step-header {
      padding: 8px 10px;
      background: #fafafa;
      font-size: 1.5rem;
      font-weight: 800; }
      .product-component .step1 .step-header:not(.text-right),
      .product-component .step2 .step-header:not(.text-right),
      .product-component .step3 .step-header:not(.text-right),
      .product-component .step4 .step-header:not(.text-right) {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    .product-component .step1 .alert,
    .product-component .step2 .alert,
    .product-component .step3 .alert,
    .product-component .step4 .alert {
      margin: 10px 10px 20px; }
    .product-component .step1 button,
    .product-component .step2 button,
    .product-component .step3 button,
    .product-component .step4 button {
      margin: 10px 10px 10px 0; }
  .product-component .step2,
  .product-component .step3 {
    border-top: none; }
  .product-component input[type=number] {
    appearance: none;
    -moz-appearance: textfield;
    width: 55px;
    min-width: 55px;
    float: left; }
    .product-component input[type=number]::-webkit-inner-spin-button, .product-component input[type=number]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0; }
    .product-component input[type=number].invalid {
      border-color: #a94446; }
  .product-component .input-number-container {
    display: flex; }
  .product-component .btn-group-vertical .btn-qty {
    width: 1.7rem;
    height: 1.7rem;
    margin: 0;
    text-align: center;
    padding: 0;
    line-height: 1.7rem;
    border-width: 0 !important;
    border-radius: 0 !important;
    border-color: #e8e8e8; }
  .product-component .error-field {
    border-color: #a94446; }
  .product-component .c-error {
    color: #a94446;
    font-size: 1.2rem;
    margin-top: 0.2rem;
    white-space: nowrap; }
  .product-component label.required:after {
    position: absolute; }

.drop-box {
  position: relative;
  min-height: 100px;
  height: 100%;
  text-align: center;
  border: 4px dashed #DDD;
  background: #F8F8F8;
  cursor: pointer; }
  .drop-box .msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .drop-box.accept-file {
    border-color: #00AA00; }
  .drop-box.reject-file {
    border-color: #d80814; }

.table-attachments {
  margin-bottom: 3rem;
  border: 1px solid #e8e8e8; }
  .table-attachments__row {
    transition: all linear 0.35s;
    display: flex;
    align-items: center; }
    .table-attachments__row.ng-move, .table-attachments__row.ng-enter, .table-attachments__row.ng-leave.ng-leave-active {
      opacity: 0;
      transform: scale(0); }
    .table-attachments__row.ng-move.ng-move-active, .table-attachments__row.ng-enter.ng-enter-active, .table-attachments__row.ng-leave {
      opacity: 1;
      transform: scale(1); }
    .table-attachments__row--header {
      background: #fafafa;
      min-height: 45px; }
    .table-attachments__row + .table-attachments__row {
      border-top: 1px solid #e8e8e8; }
  .table-attachments__col--action {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .table-attachments__status {
    margin-right: 3rem; }
    .table-attachments__status--error {
      font-weight: bold;
      color: red; }
    .table-attachments__status--success {
      font-weight: bold;
      color: green; }

.product-table {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 20px; }

.furniture-table-header {
  border: 1px solid #e8e8e8;
  background: #FAFAFA;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem; }

.table-header-products {
  border: 1px solid #e8e8e8;
  background: #FAFAFA;
  padding: 7px;
  font-size: 1.3rem; }

.btn-product {
  padding: 3px 9px !important; }

.furniture-loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.spinner {
  height: 120px;
  width: 120px;
  border: 6px solid;
  border-color: black transparent black transparent;
  border-radius: 50%;
  animation: spin 1.3s linear infinite;
  margin: 0 auto; }

@keyframes spin {
  to {
    transform: rotate(360deg); } }

form[name="furnitureRegistration"] .cg-busy-animation {
  top: 50%; }

sg-header header {
  position: relative;
  z-index: 75;
  margin-bottom: 0;
  background: #fff;
  border: none; }
  sg-header header .header1 {
    position: relative;
    z-index: 100;
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8; }
    sg-header header .header1 .navbar-right {
      margin-right: 0; }
    sg-header header .header1 .navbar-nav > li.logged-user-name {
      border-right: 1px solid #e8e8e8; }
      sg-header header .header1 .navbar-nav > li.logged-user-name > a {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden; }
    sg-header header .header1 .navbar-nav > li > a {
      padding: 0 1rem;
      color: #4a5456;
      height: 3rem;
      line-height: 3rem;
      font-size: 1.3rem; }
      sg-header header .header1 .navbar-nav > li > a.arrow-after-down {
        padding-right: 2.5rem; }
    sg-header header .header1 .navbar-nav > li > ul a {
      color: #4a5456; }
    sg-header header .header1 .navbar-nav > li.open > a:after {
      border-width: 2px 0 0 2px;
      margin-top: -0.2rem; }
  sg-header header .header2 {
    margin-top: 1.5rem; }
    sg-header header .header2 .navbar-brand {
      height: auto;
      padding: 0; }
    sg-header header .header2 .helpline {
      text-align: right;
      font-size: 1.8rem;
      line-height: 5rem; }
  sg-header header .main-menu > nav {
    margin-top: 1.5rem;
    position: relative; }
    sg-header header .main-menu > nav > .nav-border {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 15px;
      right: 15px;
      bottom: 0;
      border: 1px solid #e8e8e8; }
    sg-header header .main-menu > nav > .navbar-nav {
      position: relative;
      z-index: 75;
      float: none; }
      sg-header header .main-menu > nav > .navbar-nav > li > a {
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8; }
      sg-header header .main-menu > nav > .navbar-nav > li:hover > a {
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8; }
      sg-header header .main-menu > nav > .navbar-nav li:first-child > a:focus {
        border-left: solid #e8e8e8 1px; }
      sg-header header .main-menu > nav > .navbar-nav a, sg-header header .main-menu > nav > .navbar-nav a:hover, sg-header header .main-menu > nav > .navbar-nav a:active, sg-header header .main-menu > nav > .navbar-nav a:focus {
        border-color: #e8e8e8; }
      sg-header header .main-menu > nav > .navbar-nav li {
        float: left;
        text-align: center; }
        sg-header header .main-menu > nav > .navbar-nav li.products {
          width: 17%; }
          sg-header header .main-menu > nav > .navbar-nav li.products > a {
            border-right: 1px solid #e8e8e8; }
          sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list {
            width: 100%;
            display: none;
            position: absolute;
            margin-top: -1px; }
            sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > .dropdown-menu {
              margin: 0;
              display: block;
              box-shadow: none; }
            sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category {
              width: 100%;
              position: relative;
              z-index: 75; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li:first-child > .title {
                border-top: 1px solid #e8e8e8; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li:last-of-type.small-subcategory .subcategory-tab {
                bottom: 0; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li > .title {
                position: relative;
                z-index: 100;
                display: block;
                border-bottom: 1px solid #e8e8e8;
                border-left: 1px solid #e8e8e8;
                border-right: 1px solid #e8e8e8;
                padding: 0.8rem 1.4rem; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li > .all-products {
                font-style: italic;
                text-align: center; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li > a:hover {
                color: #ff6d00; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li.small-subcategory {
                position: relative; }
                sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li.small-subcategory .subcategory-tab {
                  top: inherit;
                  bottom: -37px; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab {
                position: absolute;
                left: 100%;
                top: 0;
                margin-left: -1px;
                width: 856px;
                display: none;
                z-index: 50;
                background: #fff;
                border: solid 1px #e8e8e8; }
                sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .border-left {
                  border-left: solid 1px #e8e8e8; }
                sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .recommends-box {
                  height: 100%; }
                sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body {
                  padding-right: 15px;
                  padding-left: 15px;
                  padding-bottom: 15px; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .see-all {
                    padding: 0; }
                    sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .see-all:hover {
                      background: transparent;
                      color: #ff6d00; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body > .row {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .title-subcategory {
                    display: block; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .recommended-in-subcategory {
                    display: block; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .subcategory-container-list {
                    overflow: auto;
                    display: block; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .div-recommended-container-list {
                    overflow: auto;
                    display: block; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-list {
                    margin: 0;
                    padding: 0; }
                    sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-list.multi-column {
                      column-count: 2; }
                    sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-list a {
                      display: block;
                      padding-left: 0; }
                      sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-list a:hover {
                        background: transparent;
                        color: #ff6d00;
                        cursor: pointer; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-product-list {
                    margin: 0;
                    padding: 0; }
                    sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-product-list li {
                      border-bottom: solid 1px #e8e8e8;
                      padding-bottom: 1rem;
                      margin-bottom: 1rem; }
                      sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-product-list li .price {
                        margin-top: 1rem;
                        margin-bottom: 1rem; }
                      sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-product-list li .discount-group {
                        color: #A3A3A3; }
                    sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body ul.subcategory-product-list li:last-child {
                      border-bottom: none; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .h2 {
                    font-size: 2rem;
                    border-bottom: solid 1px #e8e8e8;
                    padding-bottom: 1.5rem; }
                    sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .h2 .title {
                      margin-right: 1.5rem; }
                  sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .subcategory-tab .subcategory-body .recommended-buttons {
                    float: right; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li:hover .subcategory-tab {
                display: block; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li:hover > .title {
                border-right: none;
                background: #fff; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li:hover > .all-products {
                border-right: 1px solid #e8e8e8;
                cursor: pointer; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .row {
                display: table; }
              sg-header header .main-menu > nav > .navbar-nav li.products .category-products-list > ul.top-category > li .row .no-float {
                display: table-cell;
                float: none; }
        sg-header header .main-menu > nav > .navbar-nav li a {
          color: #4a5456;
          text-transform: uppercase;
          font-size: 1.4rem;
          font-weight: bold;
          padding: 0.8rem 1.4rem; }
          sg-header header .main-menu > nav > .navbar-nav li a:hover {
            background-color: #fafafa; }
          sg-header header .main-menu > nav > .navbar-nav li a.active {
            background-color: #fff;
            color: #ff6d00; }
            sg-header header .main-menu > nav > .navbar-nav li a.active:hover {
              background-color: #fafafa; }
        sg-header header .main-menu > nav > .navbar-nav li.dropdown > a {
          padding-right: 2.5rem;
          background-color: transparent; }
          sg-header header .main-menu > nav > .navbar-nav li.dropdown > a:focus {
            background-color: #fafafa; }
          sg-header header .main-menu > nav > .navbar-nav li.dropdown > a.arrow-after-down:after {
            margin-top: -0.6rem; }
        sg-header header .main-menu > nav > .navbar-nav li.dropdown li {
          display: block;
          float: none;
          text-align: left; }
          sg-header header .main-menu > nav > .navbar-nav li.dropdown li a {
            text-transform: none;
            font-weight: normal; }
            sg-header header .main-menu > nav > .navbar-nav li.dropdown li a:hover {
              background-color: #f5f5f5; }

sg-header .header-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  background: #ffffff;
  opacity: 0.8;
  display: none; }

.uib-dropdown-open #category-products-list {
  display: block; }

.uib-dropdown-open .header-overlay {
  display: block; }

.notification {
  display: block;
  width: 100%;
  overflow: hidden;
  background: #00018a;
  color: white;
  border-bottom: 1px solid #e8e8e8;
  font-weight: bold;
  padding: 1rem 0; }
  .notification.ng-enter, .notification.ng-leave {
    transition: height ease-in-out .5s; }
  .notification.ng-enter, .notification.ng-leave.ng-leave-active {
    height: 0; }
  .notification.ng-leave, .notification.ng-enter.ng-enter-active {
    height: 4rem; }
  .notification .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 14px;
    font-height: 2rem;
    white-space: nowrap;
    overflow: hidden; }
    .notification .container::before {
      display: none; }
    .notification .container::after {
      content: none; }
    .notification .container .notification-container-after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      height: 100%;
      width: 50px;
      background: linear-gradient(to right, rgba(0, 1, 138, 0), #00018a 50%);
      z-index: 1;
      flex: 0 0 auto; }
  .notification__close {
    flex: 0 0 auto;
    z-index: 2; }
    .notification__close:hover, .notification__close:focus {
      text-decoration: none; }
    .notification__close::before {
      height: 1.5rem;
      line-height: 0;
      font-size: 1.5rem;
      margin-right: 1rem;
      content: "\2715"; }
  .notification__footer {
    background: #ff6d00;
    color: #242424;
    border-bottom: none;
    font-weight: normal; }
    .notification__footer .container {
      font-size: 1.2rem; }
      .notification__footer .container::after {
        background: none !important; }
      .notification__footer .container a {
        color: #fafafa; }

.issue-module .issue-list h1 {
  display: inline-block; }

.issue-module .issue-list .date-container {
  display: flex;
  align-items: center; }
  .issue-module .issue-list .date-container datepicker {
    margin: 5px 0; }
  .issue-module .issue-list .date-container label {
    margin-right: 10px; }

.issue-module .issue-item .content table.bold {
  font-weight: 800; }

.issue-module .issue-item .content table tr {
  display: block;
  margin-bottom: 10px; }

.issue-module .issue-new-report .error_container,
.issue-module .issue-new-report .content {
  margin-top: 20px; }

.issue-module .issue-new-report .add-product {
  margin-bottom: 30px; }

.issue-module .issue-new-report .btn-primary {
  margin-left: 10px; }

.issue-module .issue-new-report .btn-danger {
  line-height: initial; }

.issue-module .issue-new-report td:last-of-type {
  white-space: nowrap; }

.issue-module .issue-new-report .input_detail {
  margin-left: 5px;
  color: #A3A3A3; }

.issue-module .issue-new-report .item-editing {
  background-color: #d9edf7; }
  .issue-module .issue-new-report .item-editing td {
    background-color: transparent; }

.table-attachments {
  margin-top: 2rem;
  border: 1px solid #e8e8e8; }

.offer-module header.page-header h1 {
  display: inline-block; }
  .offer-module header.page-header h1 small {
    display: inline-block;
    font-size: 12px;
    padding: 0 1rem; }

.offer-module header.page-header button.btn {
  vertical-align: super;
  margin: 5px 0;
  padding: 5px 10px;
  font-size: 13px; }

.payment-module .payment-header-block {
  margin-bottom: 10px; }
  .payment-module .payment-header-block .block-label,
  .payment-module .payment-header-block .block-value {
    display: block; }
  .payment-module .payment-header-block .block-label {
    text-transform: capitalize; }
    .payment-module .payment-header-block .block-label:after {
      content: ':'; }
  .payment-module .payment-header-block .block-value {
    font-weight: bold; }

.payment-module h4 {
  margin-top: 1.5rem; }

.order-module header .row, .orders-module header .row {
  hyphens: auto;
  word-break: break-all; }

.order-module .form-group p.form-control-static, .orders-module .form-group p.form-control-static {
  font-weight: bold;
  padding-top: 5px; }

.order-module .order-amounts order-amounts-summary, .orders-module .order-amounts order-amounts-summary {
  margin-bottom: 50px; }

.order-module .shipment-date-container, .orders-module .shipment-date-container {
  position: relative; }
  .order-module .shipment-date-container .address-source-options, .orders-module .shipment-date-container .address-source-options {
    position: relative;
    bottom: 0;
    right: 0;
    margin-bottom: 15px;
    font-size: 12px; }
    .order-module .shipment-date-container .address-source-options a, .orders-module .shipment-date-container .address-source-options a {
      cursor: pointer; }

.order-module .complete-order, .orders-module .complete-order {
  margin: 1.5rem 0; }

.order-module h4, .orders-module h4 {
  text-transform: uppercase;
  font-weight: bold; }
  .order-module h4:not(:first-child), .orders-module h4:not(:first-child) {
    margin-top: 30px; }

.order-module h5, .orders-module h5 {
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 1px solid #4a5456; }

.order-module footer, .orders-module footer {
  margin-top: 50px;
  margin-bottom: 20px; }

.order-module .cod-amount-form-group label, .orders-module .cod-amount-form-group label {
  padding-left: 15px;
  margin-right: 5px; }
  .order-module .cod-amount-form-group label:after, .orders-module .cod-amount-form-group label:after {
    content: ":"; }

.order-module .cod-amount-form-group input, .orders-module .cod-amount-form-group input {
  width: 150px;
  display: inline-block; }

.order-module button.btn-primary, .orders-module button.btn-primary {
  font-weight: bold;
  text-transform: uppercase; }

.order-module sg-order-summary, .orders-module sg-order-summary {
  margin-bottom: 40px; }

.order-module .order-step-1 textarea.form-control, .orders-module .order-step-1 textarea.form-control {
  height: 106px;
  padding: 12px; }

.order-module .order-step-1 footer .btn-link, .orders-module .order-step-1 footer .btn-link {
  margin: 0.7rem 1.3rem; }

.order-module .sg-cod-amount label, .orders-module .sg-cod-amount label {
  font-size: 1rem;
  padding: 0 !important; }

.orders-module .orderedItems th:first-child,
.orders-module .orderShipments th:first-child,
.orders-module .orderInvoices th:first-child {
  width: 50px; }

.orders-module .orderedItems h4,
.orders-module .orderShipments h4,
.orders-module .orderInvoices h4 {
  margin-top: 35px;
  margin-bottom: 15px; }

.discount-group {
  color: #A3A3A3; }

.product-module header {
  margin-bottom: 20px; }
  .product-module header h1,
  .product-module header [uib-pagination] {
    margin-top: 1.5rem; }

.product-module .products-counter {
  font-size: 1.7rem;
  line-height: 3.5rem; }
  .product-module .products-counter span:after {
    content: ':'; }

.product-module .sort-label {
  text-align: right;
  line-height: 32px; }
  .product-module .sort-label:after {
    content: ':'; }

.product-module table.table-striped td h4 {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1rem; }

.product-module table.table-striped td .attributes {
  font-size: 1.2rem; }
  .product-module table.table-striped td .attributes .attr {
    display: inline-block;
    margin-top: 0.5rem; }
    .product-module table.table-striped td .attributes .attr:not(:last-child) {
      padding-right: 2rem; }
    .product-module table.table-striped td .attributes .attr .attrName {
      color: #A3A3A3; }
      .product-module table.table-striped td .attributes .attr .attrName:after {
        content: ":"; }

.product-module table.table-striped td.icons {
  min-width: 65px; }
  .product-module table.table-striped td.icons product-related-button-launcher {
    margin-left: 5px; }

.product-module table.table-striped td .deprecated {
  text-decoration: line-through;
  color: #A3A3A3; }

.product-module table.table-striped td .promo-price {
  white-space: nowrap; }

.product-module table.table-striped td .reseller-price {
  font-weight: bold; }

.product-module table.table-striped td .reseller-discount {
  white-space: nowrap; }
  .product-module table.table-striped td .reseller-discount .discount-group {
    color: #A3A3A3; }

.product-module footer .btn {
  margin: 20px 0; }

.product-module .link:hover {
  cursor: pointer;
  text-decoration: underline; }

.modal-body .product-module.no-padding {
  padding: 0; }

.modal-body .product-module {
  padding: 1.5rem; }
  .modal-body .product-module .product-items {
    max-height: 365px;
    overflow: auto; }
  .modal-body .product-module .product-name-attr {
    padding: 10px 10px 10px 20px;
    max-width: 680px; }
    .modal-body .product-module .product-name-attr h3 {
      margin-bottom: 10px !important; }
    .modal-body .product-module .product-name-attr .attr {
      padding-bottom: 0;
      padding-right: 1.5rem;
      display: inline-block; }
      .modal-body .product-module .product-name-attr .attr .attrName {
        color: #A3A3A3; }
        .modal-body .product-module .product-name-attr .attr .attrName:after {
          content: ':'; }

.filter-box {
  margin-bottom: 1rem; }
  .filter-box .filter-header {
    background-color: #fafafa;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    font-weight: bold;
    font-size: 1.3rem;
    vertical-align: middle;
    padding: 1rem 8px; }
    .filter-box .filter-header .filter-buttons {
      float: right; }
      .filter-box .filter-header .filter-buttons .filter-button {
        padding-left: 2px;
        padding-right: 2px; }
  .filter-box .filter-body {
    padding: 0 8px; }

.bank_account {
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  margin-bottom: 20px; }

[sg-access].no-access {
  display: none !important; }

sg-account-address-selection-table {
  display: block;
  margin-bottom: 20px; }
  sg-account-address-selection-table .table {
    margin-bottom: 0; }
    sg-account-address-selection-table .table th.column-building {
      width: 80px; }
    sg-account-address-selection-table .table th.column-postcode {
      width: 110px; }
    sg-account-address-selection-table .table th.column-city {
      width: 180px; }

sg-account-balance > div {
  border: solid #e8e8e8 1px;
  padding: 5px; }

sg-account-balance > .row > div:last-child {
  border-left: solid #e8e8e8 1px; }

sg-account-balance .account-balance-item {
  margin-top: 5px;
  margin-bottom: 5px; }
  sg-account-balance .account-balance-item .item-value {
    text-align: right;
    font-weight: bold; }

sg-contact-mozaic {
  display: block;
  margin-top: 10px; }
  sg-contact-mozaic:first-child {
    margin-top: 0; }
  sg-contact-mozaic h5:after {
    content: ':'; }
  sg-contact-mozaic .employee span {
    display: block; }

active-cart-widget {
  float: right;
  display: inline-block;
  min-height: 45px;
  max-width: 220px; }
  active-cart-widget .main-container {
    padding: 2px 10px;
    background-color: #FFF;
    outline: none;
    border: solid 1px rgba(193, 196, 197, 0);
    transition: border .3s ease-in-out;
    width: 220px;
    /* Add a cart icon to the left side of the element */
    /* Information of the current active cart */
    /* "No active cart" message */ }
    active-cart-widget .main-container:not([disabled]):hover {
      border: solid 1px rgba(193, 196, 197, 0.3); }
      active-cart-widget .main-container:not([disabled]):hover .cart-icon:before {
        transform: scale(1.1); }
    active-cart-widget .main-container .with-cart-icon {
      padding-left: 40px;
      position: relative; }
      active-cart-widget .main-container .with-cart-icon:before {
        position: absolute;
        left: 0;
        top: 0;
        margin: -2px -8px 0 -8px;
        height: 39px;
        width: 46px;
        display: block;
        content: ' ';
        background-image: url("img/cart_icon.png");
        background-position-y: 51px;
        background-position-x: 85px;
        transition: transform .15s ease-in-out, filter .5s ease-in-out; }
    active-cart-widget .main-container .cart-active {
      margin: 4px 0; }
      active-cart-widget .main-container .cart-active .cart-name {
        display: block;
        max-width: 160px;
        font-weight: bold;
        line-height: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      active-cart-widget .main-container .cart-active .cart-amount {
        margin-left: 5px; }
    active-cart-widget .main-container .no-cart-active {
      margin: 4px 0;
      height: 35px;
      display: flex;
      align-items: center; }
      active-cart-widget .main-container .no-cart-active:before {
        filter: grayscale(85%); }
      active-cart-widget .main-container .no-cart-active:hover:before {
        filter: grayscale(0); }

sg-add-to-cart {
  display: block;
  text-align: right; }
  sg-add-to-cart input[type=number].form-control {
    width: 55px;
    min-width: 55px; }
    sg-add-to-cart input[type=number].form-control.invalid {
      border-color: #a94446; }
  sg-add-to-cart input[type=number]::-webkit-inner-spin-button,
  sg-add-to-cart input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  sg-add-to-cart .btn.btn-primary {
    padding: 0.7rem 1.2rem !important; }
  sg-add-to-cart .btn-group-vertical .btn-qty {
    width: 1.7rem;
    height: 1.7rem;
    margin: 0;
    text-align: center;
    padding: 0 !important;
    line-height: 1.7rem;
    border-width: 0 !important;
    border-radius: 0 !important;
    border-color: #e8e8e8; }

sg-cart-actions-bar {
  width: 100%; }
  sg-cart-actions-bar .btn-default {
    height: 34px; }

sg-cart-editor header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  sg-cart-editor header .name-container {
    width: 300px; }
    sg-cart-editor header .name-container h4 {
      margin-bottom: 22px; }
  sg-cart-editor header sg-cart-actions-bar {
    margin-bottom: 16px; }

sg-cart-editor footer {
  margin-top: 10px; }
  sg-cart-editor footer .cart-summary {
    display: flex;
    align-items: flex-end; }
    sg-cart-editor footer .cart-summary > div {
      flex: 1; }
  sg-cart-editor footer button {
    margin-top: 30px; }

sg-cart-item td.input-desc,
[sg-cart-item] td.input-desc {
  text-align: right;
  padding-right: 1.5rem; }

sg-cart-item td.remove-column,
[sg-cart-item] td.remove-column {
  text-align: center; }

sg-cart-item td.icons,
[sg-cart-item] td.icons {
  min-width: 65px; }
  sg-cart-item td.icons product-related-button-launcher,
  [sg-cart-item] td.icons product-related-button-launcher {
    margin-left: 5px; }

sg-cart-item td.percentage-column .discount-group,
[sg-cart-item] td.percentage-column .discount-group {
  color: #A3A3A3; }
  sg-cart-item td.percentage-column .discount-group.deprecated,
  [sg-cart-item] td.percentage-column .discount-group.deprecated {
    text-decoration: line-through; }

sg-cart-item input[type=checkbox]:focus,
[sg-cart-item] input[type=checkbox]:focus {
  outline: none; }

sg-cart-item input[type=number],
[sg-cart-item] input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
  float: left; }
  sg-cart-item input[type=number]::-webkit-inner-spin-button, sg-cart-item input[type=number]::-webkit-outer-spin-button,
  [sg-cart-item] input[type=number]::-webkit-inner-spin-button,
  [sg-cart-item] input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0; }
  sg-cart-item input[type=number].invalid,
  [sg-cart-item] input[type=number].invalid {
    border-color: #a94446; }

sg-cart-item input.sku-input,
[sg-cart-item] input.sku-input {
  margin-left: -12px; }

sg-cart-item .btn-group-vertical .btn-qty,
[sg-cart-item] .btn-group-vertical .btn-qty {
  width: 1.7rem;
  height: 1.7rem;
  margin: 0;
  text-align: center;
  padding: 0;
  line-height: 1.7rem;
  border-width: 0 !important;
  border-radius: 0 !important;
  border-color: #e8e8e8; }

sg-cart-item input[readonly],
[sg-cart-item] input[readonly] {
  background-color: inherit;
  border: none; }

sg-cart-item .promotion-info,
[sg-cart-item] .promotion-info {
  color: #A3A3A3; }

sg-cart-item .badge,
[sg-cart-item] .badge {
  cursor: default;
  padding: 6px 10px;
  transition: background-color .2s linear; }

sg-cart-item .badge.badge-success,
[sg-cart-item] .badge.badge-success {
  background-color: #3c763d; }

sg-cart-item .badge.badge-warning,
[sg-cart-item] .badge.badge-warning {
  background-color: #a94446; }

sg-cart-item .badge.badge-onorder,
[sg-cart-item] .badge.badge-onorder {
  background-color: #ffb400; }

sg-cart-item confirmable button,
[sg-cart-item] confirmable button {
  transition: width ease-out 0.2s;
  width: 70px;
  text-align: center; }

sg-cart-item confirmable[confirmable-state="confirming"] button,
[sg-cart-item] confirmable[confirmable-state="confirming"] button {
  width: 110px; }

.loyalty-icon {
  width: 6rem; }

.cart-availability .tooltip .tooltip-inner {
  max-width: 150px; }

sg-cart-items {
  display: inline-block;
  padding-bottom: 1rem; }
  sg-cart-items .cg-busy {
    top: 50px;
    bottom: 50px;
    z-index: 100; }
  sg-cart-items thead {
    position: relative; }
    sg-cart-items thead tr.fixed {
      position: fixed;
      display: none;
      top: 0;
      z-index: 101; }
    sg-cart-items thead tr.loader {
      background: none;
      border-top: none;
      border-bottom: none !important; }
    sg-cart-items thead.fixed tr.fixed {
      display: block; }
    sg-cart-items thead th {
      box-sizing: border-box; }
    sg-cart-items thead th.id-column {
      width: 38px;
      min-width: 38px; }
    sg-cart-items thead th.select-column {
      width: 30px;
      padding: 8px 0; }
    sg-cart-items thead th.sku-column {
      width: 90px;
      min-width: 90px; }
    sg-cart-items thead th.price-column {
      width: 107px;
      min-width: 107px; }
    sg-cart-items thead th.name-column {
      width: 100%; }
    sg-cart-items thead th.qty-column {
      width: 90px;
      min-width: 90px; }
    sg-cart-items thead th.warehouse-column {
      width: 60px;
      min-width: 60px; }
    sg-cart-items thead th.availability-column {
      width: 95px;
      min-width: 95px; }
    sg-cart-items thead th.discount-column {
      width: 68px;
      min-width: 68px;
      white-space: nowrap; }
    sg-cart-items thead th.remove-column {
      width: 110px;
      min-width: 110px;
      text-align: center;
      padding: 0; }
  sg-cart-items tbody tr span.price {
    display: block; }
    sg-cart-items tbody tr span.price.price-deprecated {
      text-decoration: line-through;
      color: #A3A3A3; }
  sg-cart-items tbody tr td input[type=checkbox] + label {
    margin-top: 5px; }
  sg-cart-items tbody tr td input[type=text] {
    width: 80px;
    min-width: 80px;
    padding-left: 6px;
    padding-right: 6px; }
  sg-cart-items tbody tr td input[type=number] {
    width: 55px;
    min-width: 55px;
    padding-left: 3px;
    padding-right: 3px; }
  sg-cart-items tbody tr td.remove-column {
    padding: 0; }
  sg-cart-items tbody tr td.select-column {
    padding: 8px 0; }
  sg-cart-items tbody tr.add-new-item td {
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8;
    z-index: 101; }

sg-cart-summary {
  display: block; }
  sg-cart-summary .separator {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 6px; }
  sg-cart-summary > .row {
    margin-bottom: 5px; }
    sg-cart-summary > .row > div {
      text-align: right; }
      sg-cart-summary > .row > div:first-child {
        text-align: left; }
    sg-cart-summary > .row.summary-labels {
      font-weight: bold; }
    sg-cart-summary > .row.summary-net {
      font-weight: bold; }
      sg-cart-summary > .row.summary-net > div:first-child {
        font-weight: normal; }
    sg-cart-summary > .row.summary-gross {
      color: #A3A3A3; }

spinner {
  display: block;
  margin: 60px auto;
  text-align: center;
  font-size: 14px; }
  spinner .spinnerWrapper {
    width: 50px;
    height: 40px;
    display: block;
    margin: 15px auto;
    text-align: center; }
    spinner .spinnerWrapper > div {
      background-color: #4a5456;
      height: 100%;
      width: 6px;
      display: inline-block;
      animation: sk-stretchdelay 1.2s infinite ease-in-out; }
    spinner .spinnerWrapper .rect2 {
      animation-delay: -1.1s; }
    spinner .spinnerWrapper .rect3 {
      animation-delay: -1.0s; }
    spinner .spinnerWrapper .rect4 {
      animation-delay: -0.9s; }
    spinner .spinnerWrapper .rect5 {
      animation-delay: -0.8s; }
  spinner p {
    display: inline-block;
    margin: 0 auto; }

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }

sg-invoices-list .export-to-csv-btn {
  margin: 16px 0; }

sg-invoices-list-filters {
  display: block;
  padding: 10px; }
  sg-invoices-list-filters .range-inputs-container .form-group {
    margin-bottom: 5px; }
  sg-invoices-list-filters .range-inputs-container .has-feedback .form-control {
    padding-right: 32px; }

datepicker select, datepicker select:focus, datepicker select:hover,
.datepicker select, .datepicker select:focus, .datepicker select:hover,
[datepicker] select, [datepicker] select:focus, [datepicker] select:hover {
  background-color: #ff6d00; }

._720kb-datepicker-calendar-header:nth-child(odd) {
  background: #ff6d00; }

._720kb-datepicker-calendar-header:nth-child(even) {
  background: #ff8a33; }

._720kb-datepicker-calendar-header a:hover {
  color: rgba(0, 0, 0, 0.9);
  background: rgba(255, 109, 0, 0.45); }

._720kb-datepicker-calendar-days-header {
  background: rgba(255, 109, 0, 0.08); }

._720kb-datepicker-calendar-header a, ._720kb-datepicker-calendar-header a:hover {
  color: rgba(250, 250, 250, 0.7); }

._720kb-datepicker-calendar-month span {
  color: rgba(250, 250, 250, 0.7); }

html {
  font-size: 10px;
  height: 100%; }

body {
  font-size: 1.3rem;
  color: #4a5456;
  font-family: 'Open Sans', sans-serif;
  height: 100%; }

.wrapper {
  min-height: 100%;
  height: auto;
  padding-bottom: 9.5rem; }

.page-header {
  margin: 1rem 0;
  padding: 0;
  border: none; }
  .page-header h1 {
    margin: 0.5rem 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0; }

h1 {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.8rem; }

.breadcrumb {
  background-color: #fafafa;
  color: #4a5456;
  font-size: 1.2rem;
  margin: 1.5rem 0 0; }
  .breadcrumb a {
    color: #4a5456; }

.table > thead > tr {
  background-color: #fafafa;
  border-top: 1px solid #e8e8e8;
  border-bottom: none; }
  .table > thead > tr:last-child {
    border-bottom: 1px solid #e8e8e8; }
  .table > thead > tr > th {
    font-size: 1.3rem;
    border-bottom: none;
    vertical-align: middle;
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .table > thead > tr.filters {
    background-color: #fff; }
    .table > thead > tr.filters > th {
      padding-top: 5px;
      padding-bottom: 5px; }
      .table > thead > tr.filters > th .form-control {
        height: 2.6rem;
        line-height: 2.6rem;
        font-weight: normal; }

.table > tbody > tr > td {
  background: #fff;
  border-top: 1px solid #e8e8e8;
  vertical-align: middle; }
  .table > tbody > tr > td.actions {
    white-space: nowrap; }
  .table > tbody > tr > td .btn {
    padding: 3px 9px; }

nav.panel {
  border: none;
  box-shadow: none; }
  nav.panel .panel-heading {
    background-color: #fff;
    border: none;
    padding: 15px 0; }
    nav.panel .panel-heading .panel-title {
      color: #4a5456;
      font-size: 2.4rem;
      font-weight: bold; }
  nav.panel .panel-body {
    padding: 0;
    background: #fafafa; }
    nav.panel .panel-body ul > li {
      border-left: 4px solid #e8e8e8;
      margin-top: 0; }
      nav.panel .panel-body ul > li > a {
        color: #4a5456;
        border-radius: 0; }
      nav.panel .panel-body ul > li.active {
        border-left: 4px solid #ff6d00; }
        nav.panel .panel-body ul > li.active > a {
          color: #4a5456;
          background-color: #fff; }

.well {
  border-radius: 0;
  border: none;
  box-shadow: none; }

.navbar-nav > li > .dropdown-menu {
  padding: 0;
  border-radius: 0;
  background: #fafafa;
  border: 1px solid #e8e8e8;
  border-left: none;
  box-shadow: none; }
  .navbar-nav > li > .dropdown-menu > li {
    border-bottom: 1px solid #e8e8e8; }
    .navbar-nav > li > .dropdown-menu > li:last-child {
      border: none; }
    .navbar-nav > li > .dropdown-menu > li > a {
      padding: 1rem;
      border-left: 4px solid #e8e8e8; }
      .navbar-nav > li > .dropdown-menu > li > a.active {
        border-left: 4px solid #ff6d00;
        background: #fff; }

.container {
  min-width: 1170px !important;
  width: 1170px !important; }

.pagination > .active > a {
  color: #4a5456;
  background: #fafafa;
  border: 1px solid #ff6d00; }
  .pagination > .active > a:focus, .pagination > .active > a:active, .pagination > .active > a:hover {
    color: #4a5456;
    background: #fafafa;
    border: 1px solid #ff6d00; }

.pagination > li.disabled {
  display: none; }

.pagination > li > a {
  border-radius: 0 !important;
  margin-left: 0.3rem;
  padding: 4px 8px;
  color: #4a5456;
  background: #fafafa;
  border: 1px solid #e8e8e8; }
  .pagination > li > a:hover {
    color: #4a5456;
    background: #fafafa;
    border: 1px solid #ff6d00; }

.humane,
.humane-flatty {
  font-family: 'Open Sans', san-serif;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  z-index: 200; }

.humane.humane-flatty-info,
.humane-flatty.humane-flatty-info {
  background-color: #d9edf7;
  color: #31708f; }

.humane.humane-flatty-success,
.humane-flatty.humane-flatty-success {
  background-color: #d6e9c6;
  color: #3c763d; }

.humane.humane-flatty-error,
.humane-flatty.humane-flatty-error {
  background-color: #f2dede;
  color: #a94446; }

.humane.humane-flatty-warning,
.humane-flatty.humane-flatty-warning {
  background-color: #fcf8e3;
  color: #4a5456; }

.cg-busy-default-sign {
  border-radius: 0;
  color: #4a5456;
  text-shadow: none;
  background-color: transparent;
  border: none;
  box-shadow: none; }

.popover {
  border-radius: 0; }

.complaint-module .filters, .issue-module .issue-list .filters, .orders-module .filters, .well {
  background: #fafafa;
  padding: 1.5rem;
  margin-bottom: 1.5rem; }
  .complaint-module .filters label, .issue-module .issue-list .filters label, .orders-module .filters label, .well label {
    font-size: 1.2rem; }
  .complaint-module .filters .form-control, .issue-module .issue-list .filters .form-control, .orders-module .filters .form-control, .well .form-control {
    height: 28px;
    padding: 6px; }
  .complaint-module .filters select.form-control, .issue-module .issue-list .filters select.form-control, .orders-module .filters select.form-control, .well select.form-control {
    padding: 0 6px; }
  .complaint-module .filters .form-control-feedback, .issue-module .issue-list .filters .form-control-feedback, .orders-module .filters .form-control-feedback, .well .form-control-feedback {
    height: 28px;
    line-height: 28px; }

.defaultShipping {
  font-size: 1rem;
  color: #A3A3A3; }

.dropdown-menu {
  border-radius: 0;
  padding: 0;
  border-width: 0; }
  .dropdown-menu > a {
    padding: 4px 10px;
    border-top: 1px solid #e8e8e8; }
    .dropdown-menu > a:first-child {
      border-top: none; }
  .dropdown-menu > .active > a {
    background: #4a5456; }
    .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
      background: #4a5456; }

.modal.center-modal-child.in .modal-dialog {
  transform: translate(-50%, -49%); }

.word-break {
  word-break: break-word; }

.clear {
  clear: both; }

[angular-ripple] {
  position: relative;
  overflow: hidden; }
  [angular-ripple] .angular-ripple {
    display: block;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transform: scale(0); }
  [angular-ripple] .angular-ripple.animate {
    animation: ripple 0.35s linear; }

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5); } }

[sticky-table-header] {
  position: relative; }
  [sticky-table-header] .sticky-clone {
    display: table;
    position: absolute;
    left: 0;
    transform: translate3d(-100000px, 0, 0);
    z-index: 1000; }
    [sticky-table-header] .sticky-clone.sticky-stuck {
      transform: translate3d(0, 0, 0); }

@-webkit-keyframes three-quarters {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-moz-keyframes three-quarters {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-o-keyframes three-quarters {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes three-quarters {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Styles for old versions of IE */
.btn-spinner {
  font-family: sans-serif;
  font-weight: 100; }

/* :not(:required) hides this rule from IE9 and below */
.btn-spinner:not(:required) {
  animation: three-quarters 1250ms infinite linear;
  border: 3px solid #e8e8e8;
  border-right-color: transparent;
  border-radius: 100%;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  text-indent: -9999px;
  width: 18px;
  height: 18px; }

.btn-spinner:not(:required) {
  margin-left: -22px;
  opacity: 0;
  transition: 0.4s margin ease-out, 0.2s opacity ease-out; }

.is-loading .btn-spinner {
  transition: 0.2s margin ease-in, 0.4s opacity ease-in;
  margin-left: 10px;
  opacity: 1; }

.btn {
  text-align: left; }

.btn {
  border-radius: 0;
  outline: none !important;
  padding: 0.7rem 1.2rem;
  font-size: 1.3rem; }

.btn-lg {
  font-size: 1.5rem;
  padding: 1.4rem 2.5rem; }

.btn-small {
  padding: 4px 8px; }

.btn-primary {
  background-color: #ff6d00;
  border: 1px solid #ff6d00;
  color: #fff; }
  .btn-primary:hover {
    background-color: #ff9f00;
    border: 1px solid #ff6d00;
    color: #fff; }
  .btn-primary:focus, .btn-primary:active {
    background-color: #ff6d00;
    border: 1px solid #ff6d00;
    color: #fff; }
    .btn-primary:focus:hover, .btn-primary:active:hover {
      background-color: #ff9f00;
      border: 1px solid #ff6d00;
      color: #fff; }
  .btn-primary:disabled {
    background-color: #ff6d00;
    border: 1px solid #ff6d00;
    color: #fff; }
    .btn-primary:disabled:hover {
      background-color: #ff6d00;
      border: 1px solid #ff6d00;
      color: #fff; }

.btn-default {
  background-color: transparent;
  border: 1px solid #4a5456;
  color: #4a5456; }
  .btn-default:hover {
    background-color: #4a5456;
    border: 1px solid #4a5456;
    color: #fff; }
  .btn-default:focus, .btn-default:active {
    background-color: transparent;
    border: 1px solid #4a5456;
    color: #4a5456; }
    .btn-default:focus:hover, .btn-default:active:hover {
      background-color: #4a5456;
      border: 1px solid #4a5456;
      color: #fff; }
  .btn-default:disabled {
    background-color: transparent;
    border: 1px solid #4a5456;
    color: #4a5456; }
    .btn-default:disabled:hover {
      background-color: transparent;
      border: 1px solid #4a5456;
      color: #4a5456; }

.btn-danger {
  background-color: #fff;
  border: 1px solid #a94446;
  color: #a94446; }
  .btn-danger:hover {
    background-color: #a94446;
    border: 1px solid #a94446;
    color: #fff; }
  .btn-danger:focus, .btn-danger:active {
    background-color: #fff;
    border: 1px solid #a94446;
    color: #a94446; }
    .btn-danger:focus:hover, .btn-danger:active:hover {
      background-color: #a94446;
      border: 1px solid #a94446;
      color: #fff; }
  .btn-danger:disabled {
    background-color: #fff;
    border: 1px solid #a94446;
    color: #a94446; }
    .btn-danger:disabled:hover {
      background-color: #fff;
      border: 1px solid #a94446;
      color: #a94446; }

.btn-success {
  background-color: #fff;
  border: 1px solid #ff6d00;
  color: #ff6d00; }
  .btn-success:hover {
    background-color: #ff6d00;
    border: 1px solid #ff6d00;
    color: #fff; }
  .btn-success:focus, .btn-success:active {
    background-color: #fff;
    border: 1px solid #ff6d00;
    color: #ff6d00; }
    .btn-success:focus:hover, .btn-success:active:hover {
      background-color: #ff6d00;
      border: 1px solid #ff6d00;
      color: #fff; }
  .btn-success:disabled {
    background-color: #fff;
    border: 1px solid #ff6d00;
    color: #ff6d00; }
    .btn-success:disabled:hover {
      background-color: #fff;
      border: 1px solid #ff6d00;
      color: #ff6d00; }

.required-legend {
  margin-bottom: 2rem; }
  .required-legend:before {
    content: '* ';
    color: #ff6d00; }

.label-required:after, label.required:after {
  content: ' *';
  color: #ff6d00; }

label {
  font-weight: normal; }
  label.inline {
    display: inline; }

.limit-th label, .limit-th select, .limit-th option {
  font-size: 1rem; }

.form-control {
  border-radius: 0;
  border: 1px solid #e8e8e8;
  outline: none !important;
  box-shadow: none !important; }
  .form-control:focus {
    border: 1px solid #c1c4c5; }

.form-group .c-error {
  display: none;
  color: #a94446;
  font-size: 1.2rem;
  margin-top: 0.2rem; }
  .form-group .c-error:not(.no-transition) {
    transition-delay: 20ms; }

.form-group.has-error span.c-error {
  display: block; }

form.show-errors .form-group .form-control.ng-invalid {
  border-color: #a94446; }

form.show-errors .form-group span.c-error {
  display: block; }

input[type=checkbox],
input[type=radio] {
  width: 28px;
  margin: 0;
  padding: 0;
  opacity: 0;
  display: none; }

input[type=radio] + label,
input[type=checkbox] + label {
  position: relative;
  background: transparent;
  padding-left: 2rem;
  min-height: 1.4rem;
  margin: 0;
  cursor: pointer;
  display: inline-block; }
  input[type=radio] + label:after,
  input[type=checkbox] + label:after {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.7rem;
    width: 1.4rem;
    height: 1.4rem;
    border: 1px solid #4a5456;
    content: " ";
    z-index: 1;
    border-radius: 0.2rem; }

input[type=checkbox].agreement + label:after {
  border: 2px solid #4a5456; }

input[type=radio]:checked + label:before,
input[type=checkbox]:checked + label:before {
  position: absolute;
  content: " ";
  top: 50%;
  margin-top: -0.5rem;
  left: 0.2rem;
  width: 1rem;
  height: 1rem;
  background: #ff6d00;
  z-index: 2;
  border-radius: 0.2rem; }

input[type=radio] + label:after {
  border-radius: 50%; }

input[type=radio]:checked + label:before {
  border-radius: 50%; }

input[type=number] {
  appearance: none;
  -moz-appearance: textfield; }

.wrapSelect {
  position: relative; }
  .wrapSelect select {
    appearance: none;
    cursor: pointer;
    border-radius: 0px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 2.5rem; }
    .wrapSelect select::-ms-expand {
      display: none; }
  .wrapSelect:after {
    position: absolute;
    right: 1rem;
    top: 50%;
    content: " ";
    margin-top: -0.6rem;
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #ff6d00;
    pointer-events: none; }

.input-group .form-control:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-btn {
  z-index: 4; }
  .input-group-btn:last-child > .btn {
    border-radius: 0; }

.input-group-addon {
  background: #fafafa;
  border-radius: 0;
  border-color: #e8e8e8; }

._720kb-datepicker-calendar {
  top: 100%; }

.btn-spinner:not(:required) {
  width: 16px;
  height: 16px; }

.btn-default .btn-spinner:not(:required) {
  border-color: #4a5456 transparent #4a5456 #4a5456; }

.btn-spinner:not(:required) {
  display: none; }

.is-loading .btn-spinner {
  display: inline-block; }

sg-issues-new-product .product-component {
  border: 1px solid #e8e8e8;
  border-top: none;
  margin: 0 0 30px; }
  sg-issues-new-product .product-component .ng-hide.ng-hide-animate {
    display: none !important; }
  sg-issues-new-product .product-component .step1,
  sg-issues-new-product .product-component .step2,
  sg-issues-new-product .product-component .step3,
  sg-issues-new-product .product-component .step4 {
    border-top: 1px solid #e8e8e8; }
    sg-issues-new-product .product-component .step1 > div:not(:last-of-type),
    sg-issues-new-product .product-component .step2 > div:not(:last-of-type),
    sg-issues-new-product .product-component .step3 > div:not(:last-of-type),
    sg-issues-new-product .product-component .step4 > div:not(:last-of-type) {
      padding: 10px; }
    sg-issues-new-product .product-component .step1 .warranty,
    sg-issues-new-product .product-component .step2 .warranty,
    sg-issues-new-product .product-component .step3 .warranty,
    sg-issues-new-product .product-component .step4 .warranty {
      border-top: 1px solid #e8e8e8;
      margin-top: 10px; }
      sg-issues-new-product .product-component .step1 .warranty b,
      sg-issues-new-product .product-component .step2 .warranty b,
      sg-issues-new-product .product-component .step3 .warranty b,
      sg-issues-new-product .product-component .step4 .warranty b {
        margin-left: 10px; }
    sg-issues-new-product .product-component .step1 .step-header,
    sg-issues-new-product .product-component .step2 .step-header,
    sg-issues-new-product .product-component .step3 .step-header,
    sg-issues-new-product .product-component .step4 .step-header {
      padding: 8px 10px;
      background: #fafafa;
      font-size: 1.5rem;
      font-weight: 800; }
      sg-issues-new-product .product-component .step1 .step-header:not(.text-right),
      sg-issues-new-product .product-component .step2 .step-header:not(.text-right),
      sg-issues-new-product .product-component .step3 .step-header:not(.text-right),
      sg-issues-new-product .product-component .step4 .step-header:not(.text-right) {
        display: flex;
        justify-content: space-between;
        align-items: center; }
    sg-issues-new-product .product-component .step1 .alert,
    sg-issues-new-product .product-component .step2 .alert,
    sg-issues-new-product .product-component .step3 .alert,
    sg-issues-new-product .product-component .step4 .alert {
      margin: 10px 10px 20px; }
    sg-issues-new-product .product-component .step1 button,
    sg-issues-new-product .product-component .step2 button,
    sg-issues-new-product .product-component .step3 button,
    sg-issues-new-product .product-component .step4 button {
      margin: 10px 10px 10px 0; }
  sg-issues-new-product .product-component .step2,
  sg-issues-new-product .product-component .step3 {
    border-top: none; }
  sg-issues-new-product .product-component input[type=number] {
    appearance: none;
    -moz-appearance: textfield;
    width: 55px;
    min-width: 55px;
    float: left; }
    sg-issues-new-product .product-component input[type=number]::-webkit-inner-spin-button, sg-issues-new-product .product-component input[type=number]::-webkit-outer-spin-button {
      appearance: none;
      margin: 0; }
    sg-issues-new-product .product-component input[type=number].invalid {
      border-color: #a94446; }
  sg-issues-new-product .product-component .input-number-container {
    display: flex; }
  sg-issues-new-product .product-component .btn-group-vertical .btn-qty {
    width: 1.7rem;
    height: 1.7rem;
    margin: 0;
    text-align: center;
    padding: 0;
    line-height: 1.7rem;
    border-width: 0 !important;
    border-radius: 0 !important;
    border-color: #e8e8e8; }
  sg-issues-new-product .product-component .error-field {
    border-color: #a94446; }
  sg-issues-new-product .product-component .c-error {
    color: #a94446;
    font-size: 1.2rem;
    margin-top: 0.2rem;
    white-space: nowrap; }
  sg-issues-new-product .product-component label.required:after {
    position: absolute; }

sg-issues-new-product .drop-box {
  position: relative;
  min-height: 100px;
  height: 100%;
  text-align: center;
  border: 4px dashed #DDD;
  background: #F8F8F8;
  cursor: pointer; }
  sg-issues-new-product .drop-box .msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  sg-issues-new-product .drop-box.accept-file {
    border-color: #00AA00; }
  sg-issues-new-product .drop-box.reject-file {
    border-color: #d80814; }

.table-attachments {
  margin-bottom: 3rem;
  border: 1px solid #e8e8e8; }
  .table-attachments__row {
    transition: all linear 0.35s;
    display: flex;
    align-items: center; }
    .table-attachments__row.ng-move, .table-attachments__row.ng-enter, .table-attachments__row.ng-leave.ng-leave-active {
      opacity: 0;
      transform: scale(0); }
    .table-attachments__row.ng-move.ng-move-active, .table-attachments__row.ng-enter.ng-enter-active, .table-attachments__row.ng-leave {
      opacity: 1;
      transform: scale(1); }
    .table-attachments__row--header {
      background: #fafafa;
      min-height: 45px; }
    .table-attachments__row + .table-attachments__row {
      border-top: 1px solid #e8e8e8; }
  .table-attachments__col--action {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .table-attachments__status {
    margin-right: 3rem; }
    .table-attachments__status--error {
      font-weight: bold;
      color: red; }
    .table-attachments__status--success {
      font-weight: bold;
      color: green; }

sg-offer-attrs-form .strong {
  font-weight: bold; }

sg-offer-editor .offer-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between; }

sg-offer-editor sg-offer-items {
  margin: 15px 0 40px 0; }

sg-offer-editor sg-cart-summary {
  margin-bottom: 40px; }

sg-offer-editor footer {
  margin-top: 30px;
  margin-bottom: 20px; }

sg-offer-editor h3.section-name {
  margin-top: 30px; }

sg-offer-editor .offer-options {
  margin-top: 20px;
  border: none;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8; }

sg-offer-item td.remove-column,
[sg-offer-item] td.remove-column,
sg-offer-item-extended td.remove-column,
[sg-offer-item-extended] td.remove-column {
  text-align: center; }

sg-offer-item td.percentage-column .discount-group,
[sg-offer-item] td.percentage-column .discount-group,
sg-offer-item-extended td.percentage-column .discount-group,
[sg-offer-item-extended] td.percentage-column .discount-group {
  color: #A3A3A3; }
  sg-offer-item td.percentage-column .discount-group.deprecated,
  [sg-offer-item] td.percentage-column .discount-group.deprecated,
  sg-offer-item-extended td.percentage-column .discount-group.deprecated,
  [sg-offer-item-extended] td.percentage-column .discount-group.deprecated {
    text-decoration: line-through; }

sg-offer-item td.percentage-column input,
[sg-offer-item] td.percentage-column input,
sg-offer-item-extended td.percentage-column input,
[sg-offer-item-extended] td.percentage-column input {
  padding-left: 2px;
  padding-right: 2px; }

sg-offer-item input[type=checkbox]:focus,
[sg-offer-item] input[type=checkbox]:focus,
sg-offer-item-extended input[type=checkbox]:focus,
[sg-offer-item-extended] input[type=checkbox]:focus {
  outline: none; }

sg-offer-item input[type=number],
[sg-offer-item] input[type=number],
sg-offer-item-extended input[type=number],
[sg-offer-item-extended] input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
  width: 55px;
  min-width: 55px;
  float: left; }
  sg-offer-item input[type=number]::-webkit-inner-spin-button, sg-offer-item input[type=number]::-webkit-outer-spin-button,
  [sg-offer-item] input[type=number]::-webkit-inner-spin-button,
  [sg-offer-item] input[type=number]::-webkit-outer-spin-button,
  sg-offer-item-extended input[type=number]::-webkit-inner-spin-button,
  sg-offer-item-extended input[type=number]::-webkit-outer-spin-button,
  [sg-offer-item-extended] input[type=number]::-webkit-inner-spin-button,
  [sg-offer-item-extended] input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0; }
  sg-offer-item input[type=number].invalid,
  [sg-offer-item] input[type=number].invalid,
  sg-offer-item-extended input[type=number].invalid,
  [sg-offer-item-extended] input[type=number].invalid {
    border-color: #a94446; }

sg-offer-item .input-number-container,
[sg-offer-item] .input-number-container,
sg-offer-item-extended .input-number-container,
[sg-offer-item-extended] .input-number-container {
  display: table; }

sg-offer-item .btn-group-vertical .btn-qty,
[sg-offer-item] .btn-group-vertical .btn-qty,
sg-offer-item-extended .btn-group-vertical .btn-qty,
[sg-offer-item-extended] .btn-group-vertical .btn-qty {
  width: 1.7rem;
  height: 1.7rem;
  margin: 0;
  text-align: center;
  padding: 0;
  line-height: 1.7rem;
  border-width: 0 !important;
  border-radius: 0 !important;
  border-color: #e8e8e8; }

sg-offer-item input[readonly],
[sg-offer-item] input[readonly],
sg-offer-item-extended input[readonly],
[sg-offer-item-extended] input[readonly] {
  background-color: inherit;
  border: none; }

sg-offer-item .promotion-info,
[sg-offer-item] .promotion-info,
sg-offer-item-extended .promotion-info,
[sg-offer-item-extended] .promotion-info {
  color: #A3A3A3; }

sg-offer-item .badge,
[sg-offer-item] .badge,
sg-offer-item-extended .badge,
[sg-offer-item-extended] .badge {
  cursor: default;
  padding: 6px 10px;
  transition: background-color .2s linear; }

sg-offer-item .badge.badge-success,
[sg-offer-item] .badge.badge-success,
sg-offer-item-extended .badge.badge-success,
[sg-offer-item-extended] .badge.badge-success {
  background-color: #3c763d; }

sg-offer-item .badge.badge-warning,
[sg-offer-item] .badge.badge-warning,
sg-offer-item-extended .badge.badge-warning,
[sg-offer-item-extended] .badge.badge-warning {
  background-color: #a94446; }

sg-offer-item confirmable button,
[sg-offer-item] confirmable button,
sg-offer-item-extended confirmable button,
[sg-offer-item-extended] confirmable button {
  transition: width ease-out 0.2s;
  width: 70px;
  text-align: center; }

sg-offer-item confirmable[confirmable-state="confirming"] button,
[sg-offer-item] confirmable[confirmable-state="confirming"] button,
sg-offer-item-extended confirmable[confirmable-state="confirming"] button,
[sg-offer-item-extended] confirmable[confirmable-state="confirming"] button {
  width: 110px; }

sg-offer-item .input-group-addon,
[sg-offer-item] .input-group-addon,
sg-offer-item-extended .input-group-addon,
[sg-offer-item-extended] .input-group-addon {
  width: auto;
  padding-left: 5px;
  padding-right: 5px; }

sg-offer-item .flex-item,
[sg-offer-item] .flex-item,
sg-offer-item-extended .flex-item,
[sg-offer-item-extended] .flex-item {
  display: flex;
  align-items: center;
  justify-items: space-around; }

sg-offer-item .warning-price,
[sg-offer-item] .warning-price,
sg-offer-item-extended .warning-price,
[sg-offer-item-extended] .warning-price {
  position: relative;
  color: #a94446;
  margin-left: 15px; }
  sg-offer-item .warning-price:after,
  [sg-offer-item] .warning-price:after,
  sg-offer-item-extended .warning-price:after,
  [sg-offer-item-extended] .warning-price:after {
    content: '';
    position: absolute;
    transform: translateX(-60%);
    height: 20px;
    width: 20px;
    border: 1px solid #a94446;
    border-radius: 50%; }

sg-offer-item input.required-empty,
[sg-offer-item] input.required-empty,
sg-offer-item-extended input.required-empty,
[sg-offer-item-extended] input.required-empty {
  border: 1px solid #a94446; }

sg-offer-item-extended .required_name,
sg-offer-item-extended .required_price,
[sg-offer-item-extended] .required_name,
[sg-offer-item-extended] .required_price {
  display: none;
  top: 48px;
  width: 120%;
  position: absolute;
  color: #a94446; }

sg-offer-item-extended .required_show,
[sg-offer-item-extended] .required_show {
  display: block; }

sg-offer-item-extended .wrapper_name,
sg-offer-item-extended .wrapper_price,
[sg-offer-item-extended] .wrapper_name,
[sg-offer-item-extended] .wrapper_price {
  position: relative;
  height: 60px;
  display: flex;
  align-items: center; }

sg-offer-items {
  display: inline-block; }
  sg-offer-items .cg-busy {
    top: 50px;
    bottom: 50px;
    z-index: 100; }
  sg-offer-items thead {
    position: relative; }
    sg-offer-items thead tr.fixed {
      position: fixed;
      display: none;
      top: 0;
      z-index: 101; }
    sg-offer-items thead tr.loader {
      background: none;
      border-top: none;
      border-bottom: none !important; }
    sg-offer-items thead.fixed tr.fixed {
      display: block; }
    sg-offer-items thead th.id-column {
      width: 40px;
      min-width: 40px; }
    sg-offer-items thead th.sku-column {
      width: 90px;
      min-width: 90px; }
    sg-offer-items thead th.price-column {
      width: 110px;
      min-width: 110px; }
    sg-offer-items thead th.name-column {
      width: 100%; }
    sg-offer-items thead th.qty-column {
      width: 100px;
      min-width: 100px; }
    sg-offer-items thead th.warehouse-column {
      width: 60px;
      min-width: 60px; }
    sg-offer-items thead th.availability-column {
      width: 100px;
      min-width: 100px; }
    sg-offer-items thead th.discount-column {
      min-width: 115px;
      white-space: nowrap; }
    sg-offer-items thead th.remove-column {
      width: 130px;
      min-width: 130px;
      text-align: center; }
  sg-offer-items tbody tr span.price {
    display: block; }
    sg-offer-items tbody tr span.price.price-deprecated {
      text-decoration: line-through;
      color: #A3A3A3; }
  sg-offer-items tbody tr input[type=text],
  sg-offer-items tbody tr input[type=number] {
    min-width: 70px;
    padding-left: 6px;
    padding-right: 6px; }
  sg-offer-items tbody tr.add-new-item td {
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8; }
  sg-offer-items .batch-discount label {
    padding-right: 10px; }
  sg-offer-items input[type=number]::-webkit-inner-spin-button,
  sg-offer-items input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    -moz-appearance: textfield;
    margin: 0; }
  sg-offer-items .show-extended {
    height: 30px; }
    sg-offer-items .show-extended a {
      float: right; }
  sg-offer-items .input-group--short {
    max-width: 120px; }

sg-offer-list-filters {
  display: block;
  padding: 10px; }
  sg-offer-list-filters .form-group {
    width: 20%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }

sg-offer-options-form h4 {
  display: inline-block;
  margin-top: 6px; }
  sg-offer-options-form h4 small {
    font-size: 12px; }

sg-offer-options-form .form-inline {
  margin-top: 18px;
  margin-bottom: 10px; }
  sg-offer-options-form .form-inline .form-group {
    margin-right: 25px; }

sg-offer-refer-form ng-form.offer-refer {
  display: block;
  background: #fafafa;
  padding: 1rem 1.5rem 0.5rem;
  margin-bottom: 1.5rem; }

sg-payments-list .export-to-csv-btn {
  margin: 16px 0; }

sg-payments-list-filters {
  display: block;
  padding: 10px; }
  sg-payments-list-filters .range-inputs-container .form-group {
    margin-bottom: 5px; }
    sg-payments-list-filters .range-inputs-container .form-group .control-label {
      padding-top: 7px;
      margin-bottom: 0;
      text-align: right; }
  sg-payments-list-filters .range-inputs-container .has-feedback .form-control {
    padding-right: 32px; }
  sg-payments-list-filters .range-inputs-container .has-feedback .form-control-feedback {
    right: 15px; }

sg-order-summary {
  display: block; }
  sg-order-summary .separator {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 6px; }
  sg-order-summary > .row {
    margin-bottom: 5px; }
    sg-order-summary > .row > div {
      text-align: right; }
      sg-order-summary > .row > div:first-child {
        text-align: left; }
    sg-order-summary > .row.summary-labels {
      font-weight: bold; }
    sg-order-summary > .row.summary-gross {
      color: #A3A3A3; }

sg-quote-details {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px; }

product-availability-icon {
  display: inline-block; }
  product-availability-icon div.icon-container {
    display: inline-block; }
    product-availability-icon div.icon-container circle {
      fill: #4a5456; }
      product-availability-icon div.icon-container circle.available {
        fill: #AFCF52; }
      product-availability-icon div.icon-container circle.onorder {
        fill: #ffb400; }
      product-availability-icon div.icon-container circle.unavailable {
        fill: #a94446; }

product-filter-active .filter-body .container-list, product-filter-active .filter-body .container-tags {
  margin-top: 0.5rem; }

product-filter-active .filter-body .container-tags {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  product-filter-active .filter-body .container-tags .tag {
    display: inline-block;
    margin: 0.5rem;
    line-height: 2rem;
    background: #fafafa;
    border: solid thin #e8e8e8;
    border-radius: 3px; }
    product-filter-active .filter-body .container-tags .tag > span {
      margin-left: 0.5rem; }
    product-filter-active .filter-body .container-tags .tag .delete {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      cursor: pointer;
      border-radius: 3px;
      background: transparent; }
      product-filter-active .filter-body .container-tags .tag .delete:hover {
        color: #ff6d00; }

product-filter-active .filter-footer {
  padding: 0 8px; }
  product-filter-active .filter-footer button {
    margin-bottom: 0.5rem; }

product-filter-category a:hover {
  cursor: pointer; }

product-filter-category .container-menu {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -8px; }

product-filter-category .show-all {
  max-height: none; }

product-filter-category ul.category-list {
  margin: 0;
  padding: 0;
  list-style: none; }
  product-filter-category ul.category-list li a {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: block; }

product-filter-category .no-children {
  padding: 0.5rem;
  color: #A3A3A3;
  text-align: center; }

.product-filter-container .no-active-filters {
  padding: 0.5rem;
  color: #A3A3A3;
  text-align: center; }

.product-filter-container div.filter-buttons {
  margin-top: 1rem; }
  .product-filter-container div.filter-buttons .line {
    border-top: solid 1px #e8e8e8;
    margin-bottom: 1rem; }
  .product-filter-container div.filter-buttons .buttons {
    padding-left: 8px;
    padding-right: 8px; }

.product-filter-container .price_form_to {
  padding-top: 8px;
  padding-bottom: 6px; }

.product-filter-container .arrow-after-up:after {
  right: 1rem; }

.product-filter-container .arrow-after-down:after {
  right: 1rem; }

product-filter-default .filter-header {
  cursor: pointer; }

product-filter-default .filter-body .delete {
  cursor: pointer; }
  product-filter-default .filter-body .delete:hover {
    color: #ff6d00; }

product-filter-default .filter-body .container-list {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -8px; }
  product-filter-default .filter-body .container-list ul {
    margin-top: 0.5rem; }

product-filter-default .filter-body .item {
  display: none; }

product-filter-default .filter-body .show-5-items .item:nth-child(-n+5) {
  display: block; }

product-filter-default .filter-body .show-5-items .item + .no-matches:nth-child(-n+5) {
  display: none; }

product-filter-default .filter-body .show-all-items .matches {
  display: block; }

product-filter-default .filter-body .item-button {
  display: block; }

product-filter-default .filter-body .origin-selected {
  display: block; }

product-filter-default .filter-body .selected {
  display: block; }

product-filter-default .filter-body .show-more-and-less {
  margin-bottom: 0; }
  product-filter-default .filter-body .show-more-and-less li {
    margin-bottom: 0.5rem; }
  product-filter-default .filter-body .show-more-and-less span {
    position: relative;
    color: #ff6d00;
    padding-left: 2rem; }
    product-filter-default .filter-body .show-more-and-less span:hover {
      cursor: pointer; }
    product-filter-default .filter-body .show-more-and-less span.more:before {
      position: absolute;
      left: 0.3rem;
      top: 50%;
      content: " ";
      margin-top: -.5rem;
      width: .8rem;
      height: .8rem;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #ff6d00; }
    product-filter-default .filter-body .show-more-and-less span.less:before {
      position: absolute;
      left: 0.3rem;
      top: 50%;
      content: " ";
      margin-top: -.2rem;
      width: .8rem;
      height: .8rem;
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: #ff6d00; }

product-filter-default .filter-footer {
  padding: 0 8px; }
  product-filter-default .filter-footer button {
    margin-bottom: 0.5rem; }

product-filter-price .filter-header {
  cursor: pointer; }

product-filter-price .filter-body {
  margin-bottom: 1em; }
  product-filter-price .filter-body .filter-price {
    padding-top: 1rem; }
    product-filter-price .filter-body .filter-price > div {
      display: inline-block;
      vertical-align: top; }
      product-filter-price .filter-body .filter-price > div.price_form_to {
        width: 20px; }
      product-filter-price .filter-body .filter-price > div.price_form_to_input {
        width: 98px; }

product-filter-price .filter-footer {
  padding: 0 8px; }

product-filter-price .invalid {
  border-color: #a94446; }

product-filter-price input[type=number]::-webkit-inner-spin-button,
product-filter-price input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

product-info-button-launcher button.btn-info-small, product-info-button-launcher button.btn-info-small:active {
  width: 18px;
  height: 18.5px;
  background-image: url("img/info_icon.png");
  background-position-x: 37.5px;
  background-position-y: 49.5px;
  border: none;
  content: " ";
  transition: none;
  filter: grayscale(100%); }

product-info-button-launcher button.btn-info-small:hover {
  filter: grayscale(0); }

product-info-delivery button.btn-info-small {
  padding: 3px 9px; }
  product-info-delivery button.btn-info-small, product-info-delivery button.btn-info-small:active {
    width: 18.5px;
    height: 18.5px;
    background-image: url("img/calendar_icon.png");
    background-position-x: 19.7px;
    background-position-y: 50px;
    border: none;
    content: " ";
    transition: none;
    filter: grayscale(100%); }
  product-info-delivery button.btn-info-small:hover {
    filter: grayscale(0); }

product-info-summary {
  display: block;
  position: relative;
  padding: 10px 20px;
  min-height: 333px; }
  product-info-summary ul {
    margin: 0; }
  product-info-summary .nav-tabs {
    margin-top: 5px;
    margin-bottom: 10px; }
  product-info-summary .tab-content {
    margin-bottom: 10px; }
  product-info-summary .discount-corner {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px 15px 8px;
    color: #fafafa;
    background-color: #AFCF52;
    font-weight: bold;
    text-transform: uppercase;
    z-index: 100; }
  product-info-summary .product-picture {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center; }
    product-info-summary .product-picture img {
      max-width: 300px;
      max-height: 300px; }
  product-info-summary .page-header {
    margin-bottom: 0.5rem; }
    product-info-summary .page-header h3 {
      font-weight: bold;
      font-size: 20px;
      padding-right: 50px;
      margin-bottom: 0; }
  product-info-summary .attr {
    padding-right: 1.5rem;
    display: inline-block; }
    product-info-summary .attr .attrName {
      color: #A3A3A3; }
      product-info-summary .attr .attrName:after {
        content: ':'; }
  product-info-summary .product-info {
    font-size: 14px; }
    product-info-summary .product-info .catalog-price {
      font-size: 1.3rem; }
    product-info-summary .product-info .reseller-price {
      font-size: 1.3rem; }
    product-info-summary .product-info .reseller-discount {
      font-size: 1.3rem; }
    product-info-summary .product-info .nav-tabs > li.active a {
      color: #ff6d00; }
    product-info-summary .product-info .nav-tabs > li > a {
      color: #4a5456;
      font-weight: bold;
      border-radius: 0;
      padding: 8px 15px; }
      product-info-summary .product-info .nav-tabs > li > a:hover {
        background: #fafafa; }
    product-info-summary .product-info .tab-content ul {
      padding-left: 0rem; }
      product-info-summary .product-info .tab-content ul li {
        list-style: none;
        position: relative;
        padding-left: 1.5rem; }
        product-info-summary .product-info .tab-content ul li:before {
          position: absolute;
          content: ' ';
          left: 0;
          top: 50%;
          margin-top: -3px;
          height: 6px;
          width: 6px;
          border-radius: 50%;
          border: 1px solid #ff6d00; }
        product-info-summary .product-info .tab-content ul li a {
          color: #4a5456;
          position: relative;
          padding-right: 1rem; }
          product-info-summary .product-info .tab-content ul li a:hover {
            color: #ff6d00;
            text-decoration: none; }
          product-info-summary .product-info .tab-content ul li a:after {
            content: ' ';
            position: absolute;
            content: ' ';
            right: 0;
            top: 50%;
            margin-top: -2px;
            height: 6px;
            width: 6px;
            border: 1px solid #ff6d00;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg); }
    product-info-summary .product-info .product-properties {
      margin-bottom: 10px; }
      product-info-summary .product-info .product-properties table tbody tr td {
        padding-bottom: 2px; }
      product-info-summary .product-info .product-properties table tbody tr .product-property-label {
        color: #A3A3A3; }
        product-info-summary .product-info .product-properties table tbody tr .product-property-label:after {
          content: ':'; }
      product-info-summary .product-info .product-properties table tbody tr .product-property-value {
        color: #4a5456;
        font-weight: bold;
        padding-left: 8px; }
    product-info-summary .product-info .product-price-summary {
      margin: 10px 0;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 13px;
      color: #A3A3A3; }
      product-info-summary .product-info .product-price-summary .your-price {
        font-size: 17px;
        color: #4a5456; }
        product-info-summary .product-info .product-price-summary .your-price .badge {
          font-size: 14px;
          background-color: #AFCF52; }
        product-info-summary .product-info .product-price-summary .your-price .price-amount {
          font-weight: bold; }
      product-info-summary .product-info .product-price-summary .row {
        padding: 2px 0; }
        product-info-summary .product-info .product-price-summary .row > div:last-child {
          text-align: right; }
      product-info-summary .product-info .product-price-summary .deprecated {
        text-decoration: line-through; }
    product-info-summary .product-info product-availability-icon.no-border {
      border: none; }
    product-info-summary .product-info product-availability-icon {
      padding: 5px 0 12px 12px;
      border-bottom: 1px solid #e8e8e8;
      font-weight: bold; }
    product-info-summary .product-info .add-to-cart {
      margin-top: 20px; }

.arrow-left {
  left: 0.7rem; }

.arrow-right {
  right: 0.7rem; }

.arrow-gallery {
  font-size: 2.5rem;
  color: #ff6d00;
  cursor: pointer;
  position: absolute;
  top: 45%; }

.no-display {
  display: none; }

.container-product {
  display: flex; }

.loyalty-icon {
  width: 6rem; }

product-quick-info-button-launcher {
  display: inline-block;
  float: left; }
  product-quick-info-button-launcher button.btn-quick-info {
    margin-top: 9px;
    width: 36px;
    height: 32px;
    background-image: url("img/quick_info_pot.png");
    background-position-x: 37px;
    background-color: #fff;
    outline: none !important;
    border: none;
    transition: none;
    filter: grayscale(100%); }
  product-quick-info-button-launcher button.btn-quick-info:hover, product-quick-info-button-launcher button.btn-quick-info.active {
    filter: grayscale(0); }

sg-product-quick-info-editor header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  sg-product-quick-info-editor header .toolbar {
    min-height: 35px; }

sg-product-quick-info-item td.percentage-column .discount-group,
[sg-product-quick-info-item] td.percentage-column .discount-group {
  color: #A3A3A3; }
  sg-product-quick-info-item td.percentage-column .discount-group.deprecated,
  [sg-product-quick-info-item] td.percentage-column .discount-group.deprecated {
    text-decoration: line-through; }

sg-product-quick-info-item td.percentage-column input,
[sg-product-quick-info-item] td.percentage-column input {
  padding-left: 2px;
  padding-right: 2px; }

sg-product-quick-info-item input[type=number],
[sg-product-quick-info-item] input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
  width: 55px;
  min-width: 55px;
  float: left; }
  sg-product-quick-info-item input[type=number]::-webkit-inner-spin-button, sg-product-quick-info-item input[type=number]::-webkit-outer-spin-button,
  [sg-product-quick-info-item] input[type=number]::-webkit-inner-spin-button,
  [sg-product-quick-info-item] input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0; }
  sg-product-quick-info-item input[type=number].invalid,
  [sg-product-quick-info-item] input[type=number].invalid {
    border-color: #a94446; }

sg-product-quick-info-item .input-number-container,
[sg-product-quick-info-item] .input-number-container {
  display: table; }

sg-product-quick-info-item .btn-group-vertical .btn-qty,
[sg-product-quick-info-item] .btn-group-vertical .btn-qty {
  width: 1.7rem;
  height: 1.7rem;
  margin: 0;
  text-align: center;
  padding: 0;
  line-height: 1.7rem;
  border-width: 0 !important;
  border-radius: 0 !important;
  border-color: #e8e8e8; }

sg-product-quick-info-item input[readonly],
[sg-product-quick-info-item] input[readonly] {
  background-color: inherit;
  border: none; }

sg-product-quick-info-item .badge,
[sg-product-quick-info-item] .badge {
  cursor: default;
  padding: 6px 10px;
  transition: background-color .2s linear; }

sg-product-quick-info-item .badge.badge-success,
[sg-product-quick-info-item] .badge.badge-success {
  background-color: #3c763d; }

sg-product-quick-info-item .badge.badge-warning,
[sg-product-quick-info-item] .badge.badge-warning {
  background-color: #a94446; }

sg-product-quick-info-item confirmable button,
[sg-product-quick-info-item] confirmable button {
  transition: width ease-out 0.2s;
  width: 70px;
  text-align: center; }

sg-product-quick-info-item confirmable[confirmable-state="confirming"] button,
[sg-product-quick-info-item] confirmable[confirmable-state="confirming"] button {
  width: 110px; }

sg-product-quick-info-item .flex-item,
[sg-product-quick-info-item] .flex-item {
  display: flex;
  align-items: center;
  justify-items: space-around; }

sg-product-quick-info-items {
  display: block; }
  sg-product-quick-info-items thead {
    position: relative; }
    sg-product-quick-info-items thead th.sku-column {
      width: 140px;
      min-width: 140px; }
    sg-product-quick-info-items thead th.price-column {
      width: 110px;
      min-width: 110px; }
    sg-product-quick-info-items thead th.name-column {
      width: 100%; }
    sg-product-quick-info-items thead th.qty-column {
      width: 100px;
      min-width: 100px; }
    sg-product-quick-info-items thead th.availability-column {
      width: 100px;
      min-width: 100px; }
    sg-product-quick-info-items thead th.discount-column {
      min-width: 115px;
      white-space: nowrap; }
    sg-product-quick-info-items thead th.add-to-cart-column {
      min-width: 150px; }
  sg-product-quick-info-items tbody tr span.price {
    display: block; }
    sg-product-quick-info-items tbody tr span.price.price-deprecated {
      text-decoration: line-through;
      color: #A3A3A3; }
  sg-product-quick-info-items tbody tr input[type=text],
  sg-product-quick-info-items tbody tr input[type=number] {
    min-width: 70px;
    padding-left: 6px;
    padding-right: 6px; }
  sg-product-quick-info-items tbody tr.add-new-item td {
    background: #fafafa;
    border-bottom: 1px solid #e8e8e8; }
  sg-product-quick-info-items tbody td.add-to-cart-column .btn.btn-primary {
    padding: 0.7rem 1.2rem !important; }
  sg-product-quick-info-items input[type=number]::-webkit-inner-spin-button,
  sg-product-quick-info-items input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    -moz-appearance: textfield;
    margin: 0; }

product-related-button-launcher button.btn-info-small, product-related-button-launcher button.btn-info-small:active {
  width: 18.5px;
  height: 18.5px;
  background-image: url("img/related_icon.png");
  background-position-x: 37.5px;
  background-position-y: 31.9px;
  border: none;
  content: " ";
  transition: none;
  filter: grayscale(100%); }

product-related-button-launcher button.btn-info-small:hover {
  filter: grayscale(0); }

form.search-box {
  margin-top: 0.5rem;
  position: relative; }
  form.search-box .form-control {
    height: 4rem; }
    form.search-box .form-control::-ms-clear {
      display: none; }
  form.search-box .btn-default {
    position: absolute;
    right: 1px;
    top: 50%;
    height: 3.8rem;
    width: 3.8rem;
    margin-top: -1.9rem;
    border: none; }
    form.search-box .btn-default:hover, form.search-box .btn-default:focus, form.search-box .btn-default:active {
      background: #fff;
      color: #ff6d00; }

sg-settle-list-filters .form-control-feedback {
  right: 15px; }

sg-settle-list-filters .form-group:nth-child(n+3) {
  margin: 0 20px; }

sg-settle-list-header .row {
  padding: 1.5rem 0; }

sg-logo-selector .image-container,
.sg-logo-selector .image-container {
  position: relative;
  height: 308px;
  width: 100%;
  margin-bottom: 10px; }
  sg-logo-selector .image-container .drop-box,
  .sg-logo-selector .image-container .drop-box {
    min-height: 100px;
    height: 100%;
    text-align: center;
    border: 4px dashed #DDD;
    margin-bottom: 20px;
    background: #F8F8F8;
    cursor: pointer; }
    sg-logo-selector .image-container .drop-box .msg,
    .sg-logo-selector .image-container .drop-box .msg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    sg-logo-selector .image-container .drop-box.accept-file,
    .sg-logo-selector .image-container .drop-box.accept-file {
      border-color: #00AA00; }
    sg-logo-selector .image-container .drop-box.reject-file,
    .sg-logo-selector .image-container .drop-box.reject-file {
      border-color: #d80814; }
  sg-logo-selector .image-container img-crop,
  .sg-logo-selector .image-container img-crop {
    border: 4px solid #555;
    background-color: rgba(0, 0, 0, 0.9); }

.account-logo-container {
  position: relative;
  min-height: 100px;
  border: none; }
  .account-logo-container .show-logo-selector-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    transition: opacity .5s ease-out; }
    .account-logo-container .show-logo-selector-overlay .glyphicon {
      position: absolute;
      left: 50%;
      top: 50%;
      font-size: 25px;
      transform: translate(-50%, -60%);
      background-color: #4a5456;
      color: #fff;
      padding: 20px;
      border-radius: 50%;
      transition: transform .3s ease-out; }
    .account-logo-container .show-logo-selector-overlay:hover {
      opacity: 1; }
      .account-logo-container .show-logo-selector-overlay:hover .glyphicon {
        transform: translate(-50%, -50%); }

body {
  overflow-y: scroll; }

.nowrap,
.no-wrap {
  white-space: nowrap; }

.no-padding {
  padding: 0; }

.overflow-hidden {
  overflow: hidden; }

.login-inline-info {
  display: inline-block;
  margin-right: 10px; }

/**
 * When this class is applied to a modal window container the modal children are shown centered in the screen.
 * See the parameter "windowClass" in https://angular-ui.github.io/bootstrap/#/modal
 */
.modal.center-modal-child .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  transition: transform .3s ease-out; }
  .modal.center-modal-child .modal-dialog.modal-sm {
    width: 400px; }
  .modal.center-modal-child .modal-dialog.modal-lg {
    width: 1100px; }
  .modal.center-modal-child .modal-dialog .modal-content {
    border-radius: 0; }
  .modal.center-modal-child .modal-dialog button.close {
    position: absolute;
    background-image: url("img/close_modal_icon.png");
    width: 20px;
    height: 20px;
    top: 18px;
    right: 15px;
    padding: 5px 10px;
    opacity: .8; }
    .modal.center-modal-child .modal-dialog button.close span {
      font-size: 45px;
      font-weight: normal; }

.modal.center-modal-child.in .modal-dialog {
  transform: translate(-50%, calc(-50% - 30px)); }

.modal-backdrop.fade {
  transition: opacity .3s ease-out; }

.question-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-image: url("img/question_icon.png");
  border: none;
  content: " "; }

.has-qi span {
  padding-right: 18px;
  position: relative;
  display: block; }
  .has-qi span:after {
    width: 14px;
    height: 14px;
    background-image: url("img/question_icon.png");
    border: none;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -7px;
    content: " "; }

.paginator-summary {
  display: none; }

@media print {
  sg-header,
  sg-footer,
  .breadcrumb,
  .filters,
  [filters] form,
  th.limit-th,
  td.actions,
  .export-to-csv-btn,
  [uib-pagination],
  .alert,
  tr.add-new-item,
  product-filter-category {
    display: none !important; }
  ui-view {
    width: 100%; }
  div.container {
    width: 100% !important;
    min-width: initial !important; }
  @page {
    margin: 0; }
  .paginator-summary {
    display: inline-block; } }
