product-quick-info-button-launcher {
    display: inline-block;
    float: left;

    button.btn-quick-info {
        & {
            margin-top: 9px;
            width: 36px;
            height: 32px;
            background-image: url("img/quick_info_pot.png");
            background-position-x: 37px;
            background-color: $color5;
            outline: none !important;
            border: none;
            transition: none;
            filter: grayscale(100%);
        }
        &:hover, &.active {
            filter: grayscale(0);
        }
    }
}