sg-cart-summary {
    display: block;

    .separator {
        border-bottom: 1px solid $borderColor;
        margin-bottom: 6px;
    }

    >.row {
        margin-bottom: 5px;

        >div {
            text-align: right;
            &:first-child {
                text-align: left;
            }
        }

        &.summary-labels {
            font-weight: bold;
        }

        &.summary-net {
            font-weight: bold;
            > div:first-child {
                font-weight: normal;
            }
        }

        &.summary-gross {
            color: $color7;
        }

    }
}