sg-offer-editor {

    .offer-header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
    }

    sg-offer-items {
        margin: 15px 0 40px 0;
    }

    sg-cart-summary {
        margin-bottom: 40px;
    }

    footer {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    h3.section-name {
        margin-top: 30px;
    }

    .offer-options {
        margin-top: 20px;
        border: none;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
    }

}