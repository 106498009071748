.payment-module {

    .payment-header-block {
        margin-bottom: 10px;

        .block-label,
        .block-value {
            display: block;
        }

        .block-label {
            text-transform: capitalize;
            &:after {
                content: ':';
            }
        }

        .block-value {
            font-weight: bold;
        }

    }
    h4 {
        margin-top: 1.5rem;
    }
}