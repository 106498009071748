sg-logo-selector,
.sg-logo-selector{

    .image-container {
        position: relative;
        height: 308px;//300px img preview + 8px of borders
        width: 100%;
        margin-bottom: 10px;

        .drop-box {
            min-height: 100px;
            height: 100%;
            text-align: center;
            border: 4px dashed #DDD;
            margin-bottom: 20px;
            background: #F8F8F8;
            cursor: pointer;

            .msg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &.accept-file {
                border-color: #00AA00;
            }

            &.reject-file {
                border-color: #d80814;
            }

        }

        img-crop {
            border: 4px solid #555;
            background-color: rgba(0, 0, 0, .9);
        }

    }

}