//.modal-dialog {
//    .product-page-items {
//        max-height: 300px;
//        width:100%;
//        overflow:auto;
//        .product-info {
//            width:100%;
//        }
//        table.table-striped td h4 {
//            font-size: 1.5rem;
//            margin: 0 0 1rem;
//        }
//    }
//}

//.nav-tabs {
//    display: inline-flex;
//}

product-info-summary {
    display: block;
    position: relative;
    padding: 10px 20px;
    min-height: 333px;
    ul {
        margin: 0;
    }
    .nav-tabs {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .tab-content {
        margin-bottom: 10px;
    }
    //Promotion info in the upper left corner
    .discount-corner {
        position: absolute;
        top: 0;
        left: 0;
        padding: 5px 15px 8px;
        color: $color3;
        background-color: $color8;
        font-weight: bold;
        text-transform: uppercase;
        z-index: 100;
    }

    .product-picture {
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 300px;
            max-height: 300px;
        }
    }

    .page-header {
        margin-bottom: 0.5rem;
        h3 {
            font-weight: bold;
            font-size: 20px;
            padding-right: 50px;
            margin-bottom: 0;
        }
    }

    .attr {
       // padding-bottom: 0;
        padding-right: 1.5rem;
        display: inline-block;
        .attrName {
            color: $color7;
            &:after {
                content: ':';
            }
        }
    }
    .product-info {
        .catalog-price {
            font-size: 1.3rem;
        }
        .reseller-price {
            font-size: 1.3rem;
        }
        .reseller-discount {
            font-size: 1.3rem;
        }
        font-size: 14px;
        .nav-tabs {
            >li {
                &.active {
                    a {
                        color: $color1;
                    }
                }
                > a{
                    color: $color2;
                    font-weight: bold;
                    border-radius: 0;
                    padding: 8px 15px;
                    &:hover {
                        background: $color3;
                    }
                }
            }
        }
        .tab-content {
            //padding: 0;
            ul {
                padding-left: 0rem;
                li {
                    list-style: none;
                    position: relative;
                    padding-left: 1.5rem;
                    &:before {
                        position: absolute;
                        content: ' ';
                        left: 0;
                        top: 50%;
                        margin-top: -3px;
                        height: 6px;
                        width: 6px;
                        border-radius: 50%;
                        border: 1px solid $color1;
                    }
                    a {
                        color: $color2;
                        position: relative;
                        padding-right: 1rem;
                        &:hover {
                            color: $color1;
                            text-decoration: none;
                        }
                        &:after {
                            content: ' ';
                            position: absolute;
                            content: ' ';
                            right: 0;
                            top: 50%;
                            margin-top: -2px;
                            height: 6px;
                            width: 6px;
                            border: 1px solid $color1;
                            border-width: 1px 1px 0 0;
                            transform: rotate(45deg);
                        }
                    }
                }
            }
        }
        .product-properties {
            margin-bottom: 10px;
            table {
                tbody {
                    tr {
                        td {
                            padding-bottom: 2px;
                        }

                        .product-property-label {
                            color: $color7;
                            &:after {
                                content: ':';
                            }
                        }
                        .product-property-value {
                            color: $color2;
                            font-weight: bold;
                            padding-left: 8px;
                        }
                    }
                }
            }
        }

        .product-price-summary {
            margin: 10px 0;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 13px;
            color: $color7;
            .your-price {
                font-size: 17px;
                color: $color2;
                .badge {
                    font-size: 14px;
                    background-color: $color8;
                }
                .price-amount {
                    font-weight: bold;
                }
            }
            .row {
                padding: 2px 0;
                //Align prices to right
                &>div:last-child {
                    text-align: right;
                }
            }
            .deprecated {
                text-decoration: line-through;
            }
        }
        product-availability-icon.no-border{
            border: none;
        }
        //Component to show the availability
        product-availability-icon {
            padding: 5px 0 12px 12px;
            border-bottom: 1px solid $color4;
            font-weight: bold;
        }

        //Container of section to add the product to the cart
        .add-to-cart {
            margin-top: 20px;
        }

    }

}

.arrow-left {
    left: 0.7rem;
}

.arrow-right {
    right: 0.7rem;
}

.arrow-gallery {
    font-size: 2.5rem;
    color: #ff6d00;
    cursor: pointer;
    position: absolute;
    top: 45%;
}

.no-display {
    display: none;
}

.container-product {
    display: flex;
}