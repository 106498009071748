product-filter-default {
  .filter-header {
    cursor: pointer;
  }

  .filter-body {

    .delete {
      cursor: pointer;
      &:hover {
        color: $color1;
      }
    }

    .container-list {
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-right: -8px;

      ul {
        margin-top: 0.5rem;
      }
    }

    .item {
      display: none;
    }

    .show-5-items {
      .item:nth-child(-n+5) {
        display: block;
      }
      .item + .no-matches:nth-child(-n+5) {
        display: none;
      }
    }

    .show-all-items {
      .matches {
        display: block;
      }
    }

    .item-button {
      display: block;
    }

    .origin-selected {
      display: block;
    }

    .selected {
      display: block;
    }

    .show-more-and-less {
      margin-bottom: 0;
      li {
        margin-bottom: 0.5rem;
      }
      span {
        position: relative;
        color: $color1;
        padding-left: 2rem;
        &:hover {
          cursor: pointer;
        }
        &.more {
          &:before {
            position: absolute;
            left: 0.3rem;
            top: 50%;
            content: " ";
            margin-top: -.5rem;
            width: .8rem;
            height: .8rem;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            border-width: 0 2px 2px 0;
            border-style: solid;
            border-color: $color1;
          }
        }
        &.less {
          &:before {
            position: absolute;
            left: 0.3rem;
            top: 50%;
            content: " ";
            margin-top: -.2rem;
            width: .8rem;
            height: .8rem;
            -webkit-transform: rotate(225deg);
            transform: rotate(225deg);
            border-width: 0 2px 2px 0;
            border-style: solid;
            border-color: $color1;
          }
        }
      }
    }
  }

  .filter-footer {
    padding: 0 8px;

    button {
      margin-bottom: 0.5rem;
    }
  }
}