.issue-module {
  .issue-list {
    h1 {
      display: inline-block;
    }

    .filters {
      @extend %filter-section;
    }

    .date-container {
      display: flex;
      align-items: center;
      datepicker {
        margin: 5px 0;
      }
      label {
        margin-right: 10px;
      }
    }
  }

  .issue-item {
    .content {
      table.bold {
        font-weight: 800;
      }
      table tr {
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  .issue-new-report {
    .error_container,
    .content {
      margin-top: 20px;
    }

    .add-product {
      margin-bottom: 30px;
    }

    .btn-primary {
      margin-left: 10px;
    }

    .btn-danger {
      line-height: initial;
    }

    td:last-of-type {
      white-space: nowrap;
    }

    .input_detail {
      margin-left: 5px;
      color: $color7;
    }

    .item-editing {
      background-color: $bgInfoColor;

      td {
        background-color: transparent;
      }
    }
  }
}

.table-attachments {
  margin-top: 2rem;
  border: 1px solid $color4;
}
