.account-logo-container {
    position: relative;
    min-height: 100px;
    border: none;

    .show-logo-selector-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        //background-color: rgba(200, 200, 200, .4);

        .glyphicon {
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 25px;
            transform: translate(-50%, -60%);
            background-color: $color2;
            color: #fff;
            padding: 20px;
            border-radius: 50%;
            transition: transform .3s ease-out;
        }

        opacity: 0;
        transition: opacity .5s ease-out;

        &:hover {
            opacity: 1;
            .glyphicon {
                transform: translate(-50%, -50%);
            }
        }
    }

}
