sg-product-quick-info-items {
  display: block;
  thead {
    position: relative;
    th.sku-column {
      width: 140px;
      min-width: 140px;
    }
    th.price-column {
      width: 110px;
      min-width: 110px;
    }
    th.name-column {
      width: 100%;
    }
    th.qty-column {
      width: 100px;
      min-width: 100px;
    }
    th.availability-column {
      width: 100px;
      min-width: 100px;
    }
    th.discount-column {
      min-width: 115px;
      white-space: nowrap;
    }
    th.add-to-cart-column {
      min-width: 150px;
    }
  }
  tbody {
    tr {
      span.price {
        display: block;
        &.price-deprecated {
          text-decoration: line-through;
          color: $color7;
        }
      }
      input[type=text],
      input[type=number] {
        min-width: 70px;
        padding-left: 6px;
        padding-right:6px;
      }
      &.add-new-item {
        td {
          background: $color3;
          border-bottom: 1px solid $borderColor;
        }
      }
    }
    td.add-to-cart-column {
      .btn.btn-primary {
        padding: 0.7rem 1.2rem !important;
      }
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }
}
