%transition1 {
  transition: all .4s ease-in-out;
}

%default_link {
  color: $color1;
  &:focus,
  &:active {
    color: $color1;
  }
  &:hover {
    color: $color1;
    text-decoration: underline;
  }
  &.dark {
    color: $color2;
  }
  &.underline {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  &:disabled {
    color: $color7;
    text-decoration: none;
    &:hover {
      color: $color7;
      text-decoration: none;
    }
  }
}

.arrow-after-down {
  padding-right: 2.5rem;
  position: relative;
  &:after {
    position: absolute;
    right: 0.7rem;
    top: 50%;
    content: " ";
    margin-top: -0.5rem;
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: $color1;
  }
  .open {
    &:after {
      border-width: 0 2px 2px 0;
      margin-top: -0.1rem;
    }
  }
}

.arrow-after-up {
  padding-right: 2.5rem;
  position: relative;
  &:after {
    position: absolute;
    right: 0.7rem;
    top: 50%;
    content: " ";
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(-135deg);
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: $color1;
  }
  .open {
    &:after {
      border-width: 0 2px 2px 0;
      margin-top: -0.1rem;
    }
  }
}

.inline-elements {
  align-items: center;
}

.inline-elements img {
  position: relative;
  height: 23px;
  width: 23px;
}

.furniture-new-icon {
  margin-bottom: -4px;
  margin-top: -5px;
}
