sg-offer-items {
  display: inline-block;
  .cg-busy{
    top: 50px;
    bottom: 50px;
    z-index: 100;
  }
  thead {
    position: relative;

    tr.fixed {
      position: fixed;
      display:none;
      top: 0;
      z-index:101;
    }
    tr.loader {
      background: none;
      border-top: none;
      border-bottom: none !important;
    }
    &.fixed {
      tr.fixed {
        display:block;
      }
    }

    th.id-column {
      width: 40px;
      min-width: 40px;
    }
    th.sku-column {
      width: 90px;
      min-width: 90px;
    }
    th.price-column {
      width: 110px;
      min-width: 110px;
    }
    th.name-column {
      width: 100%;
    }
    th.qty-column {
      width: 100px;
      min-width: 100px;
    }
    th.warehouse-column {
      width: 60px;
      min-width: 60px;
    }
    th.availability-column {
      width: 100px;
      min-width: 100px;
    }
    th.discount-column {
      min-width: 115px;
      white-space: nowrap;
    }
    th.remove-column {
      width: 130px;
      min-width: 130px;
      text-align: center;
    }
  }
  tbody {
    tr {
      span.price {
        display: block;
        &.price-deprecated {
          text-decoration: line-through;
          color: $color7;
        }
      }
      input[type=text],
      input[type=number] {
        min-width: 70px;
        padding-left: 6px;
        padding-right:6px;
      }
      &.add-new-item {
        td {
          background: $color3;
          border-bottom: 1px solid $borderColor;
        }
      }
    }
  }

  .batch-discount{
    label {
      padding-right: 10px;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }

  .show-extended {
    height: 30px;
    a {
      float: right;
    }
  }

  .input-group--short {
    max-width: 120px;
  }

}
