.product-module {

  header {
    margin-bottom: 20px;

    h1,
    [uib-pagination] {
      margin-top: 1.5rem;
    }

  }
  .products-counter {
    font-size: 1.7rem;
    line-height: 3.5rem;
    span:after {
      content: ':';
    }
  }
  .sort-label {
    text-align: right;
    line-height: 32px;
    &:after {
      content: ':';
    }
  }
  table {
    &.table-striped {
      td {
        h4 {
          font-size: 1.5rem;
          margin-top: 0;
          margin-bottom: 1rem;
        }
        .attributes {
          font-size: 1.2rem;
          .attr {
            display: inline-block;
            margin-top: 0.5rem;
            &:not(:last-child) {
              padding-right: 2rem;
            }
            .attrName {
              color: $color7;
              &:after {
                content: ":";
              }
            }
          }
        }
        &.icons {
          min-width: 65px;
          product-related-button-launcher {
            margin-left: 5px;
          }
        }
        .deprecated {
          text-decoration: line-through;
          color: $color7;
        }
        .promo-price {
          white-space: nowrap;
        }
        .reseller-price {
          font-weight: bold;
        }
        .reseller-discount {
          white-space: nowrap;
          .discount-group {
            color: $color7;
          }
        }
      }

    }
  }

  footer {

    .btn {
      margin: 20px 0;
    }

  }

  .link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.modal-body {
  .product-module.no-padding{
    padding: 0;
  }
  .product-module {
    padding: 1.5rem;
    .product-items {
      max-height: 365px;
      overflow: auto;
    }
    .product-name-attr {
      padding: 10px 10px 10px 20px;
      max-width: 680px;
      h3 {
        margin-bottom: 10px!important;
      }
      .attr {
        padding-bottom: 0;
        padding-right: 1.5rem;
        display: inline-block;

        .attrName {
          color: $color7;

          &:after {
            content: ':';
          }
        }
      }
    }
  }
}

.filter-box {
  margin-bottom: 1rem;

  .filter-header {
    background-color: $color3;
    border-top: 1px solid $color4;
    border-bottom: 1px solid $color4;
    font-weight: bold;
    font-size: 1.3rem;
    vertical-align: middle;
    padding: 1rem 8px;

    .filter-buttons {
      float: right;

      .filter-button {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }

  .filter-body {
    padding: 0 8px;
  }

}