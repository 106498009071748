.dashboard {
  .dashboard-panel {
    background: $color3;
    border: 1px solid $borderColor;
    padding: 2rem 2rem;
    height: 10rem;
    margin-bottom:1.5rem;
    margin-top:1.5rem;
    display: block;
    color: $color2;
    text-decoration: none;
    @extend %transition1;
    cursor: pointer;
    &:hover {
      border-color: $color1;
    }
    h4 {
      padding: 0;
      margin: 0 0 0.5rem 0;
      font-size: 1.9rem;
      font-weight: bold;
    }
    > span {
      display: block;
      font-size: 1.2rem;
      color: $color7;
    }
  }
  .home-news {
    margin-top: 2rem;
    margin-bottom: 2rem;
    article {
      color: white;
      p {
        font-size: 1.2rem;
        color: #A3A3A3;
      }
      .default-container {
        padding: 5px 10px 10px;
        h4 {
          font-size: 1.3rem;
        }
        time {
          color: white;
        }
        p {
          font-size: 1.2rem;
          color: white;
        }
      }
      height: 163px;
      margin-top: 15px;
      .article-background {
        width: 360px;
        height: 163px;
        position: absolute;
        z-index: 1;
      }
      .default-bg {
        z-index: -1;
        background-color: #00018a;
      }
    }
    .see-all {
      margin-top: 1rem;
    }
    h2 {
      text-transform: uppercase;
      font-size: 1.7rem;
      font-weight: 300;
      margin: 1rem 0;
      padding: 0 0 1rem 0;
      border-bottom: 1px solid $borderColor;
    }
    h4 {
      font-size: 1.4rem;
      font-weight: bold;
      margin: 0.7rem 0 0.2rem 0;
    }
    time {
      display: block;
      color: $color7;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
}