sg-invoices-list-filters {
    display: block;
    padding: 10px;

    .range-inputs-container {
        .form-group {
            margin-bottom: 5px;
        }
        .has-feedback {
            .form-control {
                padding-right: 32px;
            }
        }
    }

}