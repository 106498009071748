.btn {
  @extend %transition1;
  border-radius: 0;
  outline: none !important;
  padding: 0.7rem 1.2rem;
  font-size: 1.3rem;
}

.btn-lg {
  font-size: 1.5rem;
  padding: 1.4rem 2.5rem;
}

.btn-small {
  padding: 4px 8px;
}

// default button styles
@mixin button-start($border, $bg, $fontColor) {
  background-color: $bg;
  border: 1px solid $border;
  color: $fontColor;
}

// hovered button styles
@mixin button-hover($borderHover, $bgHover, $fontColorHover) {
  background-color: $bgHover;
  border: 1px solid $borderHover;
  color: $fontColorHover;
}

@mixin button($border, $borderHover, $bg, $bgHover, $fontColor, $fontColorHover) {
  @include button-start($border, $bg, $fontColor);
  &:hover {
    @include button-hover($borderHover, $bgHover, $fontColorHover);
  }
  &:focus,
  &:active {
    @include button-start($border, $bg, $fontColor);
    &:hover {
      @include button-hover($borderHover, $bgHover, $fontColorHover);
    }
  }
  &:disabled {
    @include button-start($border, $bg, $fontColor);
    &:hover {
      @include button-start($border, $bg, $fontColor);
    }
  }
}

.btn-primary {
  @include button($color1, $color1, $color1, $color1Hover, $color5, $color5);
}

.btn-default {
  @include button($color2, $color2, transparent, $color2, $color2, $color5);
}

.btn-danger {
  @include button($errorColor, $errorColor, $color5, $errorColor, $errorColor, $color5);
}

.btn-success {
  @include button($color1, $color1, $color5, $color1, $color1, $color5);
}

.btn-link {
  @extend %default_link;
}

.required-legend {
  margin-bottom: 2rem;
  &:before {
    content: '* ';
    color: $color1;
  }
}

.label-required {
  &:after {
    content: ' *';
    color: $color1;
  }
}

label {
  font-weight: normal;
  &.required {
    @extend .label-required;
  }
  &.inline {
    display: inline;
  }
}

.limit-th {
  label, select, option {
    font-size: 1rem;
  }
}

.form-control {
  border-radius: 0;
  border: 1px solid $borderColor;
  outline: none !important;
  box-shadow: none !important;
  &:focus {
    border: 1px solid $fBorderColor;
  }
}

.form-group {
  .c-error {
    display: none;
    color: $errorColor;
    font-size: 1.2rem;
    margin-top: 0.2rem;
    &:not(.no-transition) {
      @extend %transition1;
      transition-delay: 20ms; // hack to prevent error message blinking while validation promise is not resolved
    }
  }
  &.has-error {
    span.c-error {
      display: block;
    }
  }
}

form.show-errors {
  .form-group {
    .form-control {
      &.ng-invalid {
        border-color: $errorColor;
      }
    }
    span.c-error {
      display: block;
    }
  }
}

input[type=checkbox],
input[type=radio] {
  width: 28px;
  margin: 0;
  padding: 0;
  opacity: 0;
  display: none;
}

input[type=radio] + label,
input[type=checkbox] + label {
  position: relative;
  background: transparent;
  padding-left: 2rem;
  min-height: 1.4rem;
  margin: 0;
  cursor: pointer;
  display: inline-block;
  &:after {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.7rem;
    width: 1.4rem;
    height: 1.4rem;
    border: 1px solid $color2;
    content: " ";
    z-index: 1;
    border-radius: 0.2rem;
  }
}

input[type=checkbox].agreement + label {
  &:after {
    border: 2px solid $color2;
  }
}

input[type=radio]:checked + label,
input[type=checkbox]:checked + label {
  &:before {
    position: absolute;
    content: " ";
    top: 50%;
    margin-top: -0.5rem;
    left: 0.2rem;
    width: 1rem;
    height: 1rem;
    background: $color1;
    z-index: 2;
    border-radius: 0.2rem;
  }
}

input[type=radio] + label {
  &:after {
    border-radius: 50%;
  }
}

input[type=radio]:checked + label {
  &:before {
    border-radius: 50%;
  }
}

input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
}

.wrapSelect {
  position: relative;

  select {
    appearance: none;
    cursor: pointer;
    border-radius: 0px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 2.5rem;
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    position: absolute;
    right: 1rem;
    top: 50%;
    content: " ";
    margin-top: -0.6rem;
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(45deg);
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: $color1;
    pointer-events: none;
  }
}

.input-group .form-control:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-btn {
  z-index: 4;
  &:last-child > .btn {
    border-radius: 0;
  }
}

.input-group-addon {
  background: $color3;
  border-radius: 0;
  border-color: $borderColor;
}

._720kb-datepicker-calendar {
  top: 100%;
}

.btn-spinner:not(:required) {
  width: 16px;
  height: 16px;

}

.btn-default {
  .btn-spinner:not(:required) {
    border-color: $color2 transparent $color2 $color2;
  }
}

// slide in out
.btn-spinner:not(:required) {
  display: none;
}

.is-loading {
  .btn-spinner {
    display: inline-block;
  }
}
