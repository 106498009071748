.complaint-module {
  .filters {
    @extend %filter-section;
  }
  .form-group {
    .date-container {
      display: flex;
      align-items: center;
      margin: 5px 0;
      label {
        margin-right: 10px;
      }
    }
  }
  .complaint-item {
    .content {
      padding-bottom: 20px;
      table {
        border-collapse: separate;
        border-spacing: 0 0.5em;
        margin-bottom: 20px;
        td {
          padding-right: 50px;
          &:first-of-type {
            font-weight: 800;
          }
          span {
            margin-right: 10px;
          }
        }
      }
      .contact {
        p {
          margin-bottom: 5px;
        }
        &_mail {
          display: inline-block;
          margin-bottom: 5px;
        }
      }
    }
    .btn {
      float: right;
      margin-bottom: 20px;
    }
  }
}