sg-payments-list-filters {
    display: block;
    padding: 10px;

    .range-inputs-container {
        .form-group {
            margin-bottom: 5px;
            .control-label {
                padding-top: 7px;
                margin-bottom: 0;
                text-align: right;
            }
        }
        .has-feedback {
            .form-control {
                padding-right: 32px;
            }
            .form-control-feedback {
                right: 15px;
            }
        }
    }

}