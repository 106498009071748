@media print {
  sg-header,
  sg-footer,
  .breadcrumb,
  .filters,
  [filters] form,
  th.limit-th,
  td.actions,
  .export-to-csv-btn,
  [uib-pagination],
  .alert,
  tr.add-new-item,
  product-filter-category {
    display: none !important;
  }

  ui-view {
    width: 100%;
  }

  div.container {
    width: 100% !important;
    min-width: initial !important;
  }

  @page {
    margin: 0;
  }

  .paginator-summary {
    display: inline-block;
  }
}