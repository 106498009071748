sg-footer {
  display: block;
  margin-top: 1.5rem;
  width: 100%;
  background: $color2;
  color: $color5;
  z-index: 75;
  footer {
    margin-top: -9.5rem;
    height: 8rem;
    line-height: 8rem;
    a {
      margin-left: 1rem;
    }
  }
}