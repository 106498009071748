form.search-box {
  margin-top: 0.5rem;
  position: relative;
  .form-control {
    height: 4rem;
    &::-ms-clear {
      display: none;
    }
  }
  .btn-default {
    position: absolute;
    right: 1px;
    top: 50%;
    height: 3.8rem;
    width: 3.8rem;
    margin-top: -1.9rem;
    border: none;
    &:hover,
    &:focus,
    &:active {
      background: $color5;
      color: $color1;
    }
  }
}