sg-offer-list-filters {
    display: block;
    padding: 10px;

    .form-group {
        width: 20%;
        //display: inline-block;
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
}