sg-add-to-cart {
    display: block;
    text-align: right;
    input[type=number] {
        &.form-control {
            width: 55px;
            min-width: 55px;
            &.invalid {
                border-color: $errorColor;
            }
        }
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .btn.btn-primary {
        padding: 0.7rem 1.2rem !important;
    }
    .btn-group-vertical {
        .btn-qty {
            width: 1.7rem;
            height: 1.7rem;
            margin: 0;
            text-align: center;
            padding: 0!important;
            line-height: 1.7rem;
            border-width: 0!important;
            border-radius: 0!important;
            border-color: $borderColor;
        }
    }
}