sg-cart-item,
[sg-cart-item] {
    td.input-desc {
        text-align: right;
        padding-right: 1.5rem;
    }
    td.remove-column {
        text-align: center;
    }
    td.icons {
        min-width: 65px;
        product-related-button-launcher {
            margin-left: 5px;
        }
    }
    td.percentage-column {
        .discount-group {
            color: $color7;
            &.deprecated {
                text-decoration: line-through;
            }
        }
    }
    input[type=checkbox] {
        &:focus {
            outline: none;
        }
    }
    input[type=number] {
        appearance: none;
        -moz-appearance: textfield;
        float: left;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
        &.invalid {
            border-color: $errorColor;
        }
    }

    input.sku-input {
        margin-left: -12px;
    }

    .btn-group-vertical {
        .btn-qty {
            width: 1.7rem;
            height: 1.7rem;
            margin: 0;
            text-align: center;
            padding: 0;
            line-height: 1.7rem;
            border-width: 0!important;
            border-radius: 0!important;
            border-color: $borderColor;
        }
    }
    input[readonly] {
        background-color: inherit;
        border: none;
    }
    .promotion-info {
        color: $color7;
    }

    .badge {
        cursor: default;
        padding: 6px 10px;
        transition: background-color .2s linear;
    }
    .badge.badge-success {
        background-color: $successColor;
    }
    .badge.badge-warning {
        background-color: $errorColor;
    }
    .badge.badge-onorder {
        background-color: $color10;
    }

    confirmable {
        button {
            transition: width ease-out 0.2s;
            width: 70px;
            text-align: center;
        }
        &[confirmable-state="confirming"] {
            button {
                width: 110px;
            }
        }
    }

}

.loyalty-icon {
    width: 6rem;
}

.cart-availability {
    .tooltip {
        .tooltip-inner {
            max-width: 150px;
        }
    }
}

