sg-offer-item-extended,
[sg-offer-item-extended] {
  .required_name,
  .required_price {
    display: none;
    top: 48px;
    width: 120%;
    position: absolute;
    color: $errorColor;
  }

  .required_show {
    display: block;
  }

  .wrapper_name,
  .wrapper_price {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
  }
}