body {
    overflow-y: scroll;
}
.nowrap,
.no-wrap {
    white-space: nowrap;
}
.no-padding {
    padding: 0;
}
.overflow-hidden {
    overflow: hidden;
}

.login-inline-info {
    display: inline-block;
    margin-right: 10px;
}

/**
 * When this class is applied to a modal window container the modal children are shown centered in the screen.
 * See the parameter "windowClass" in https://angular-ui.github.io/bootstrap/#/modal
 */
.modal.center-modal-child {
    .modal-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
        transition: transform .3s ease-out;
        &.modal-sm {
            width: 400px;
        }
        &.modal-lg {
            width: 1100px;
        }
        .modal-content {
            border-radius: 0;
        }
        button.close {
            position: absolute;
            background-image: url("img/close_modal_icon.png");
            width: 20px;
            height: 20px;
            top: 18px;
            right: 15px;
            padding: 5px 10px;
            opacity: .8;
            span {
                font-size: 45px;
                font-weight: normal;
            }
        }
    }
    &.in .modal-dialog {
        transform: translate(-50%, calc(-50% - 30px));
    }
}
.modal-backdrop.fade {
    transition: opacity .3s ease-out;
}

.question-icon {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-image: url("img/question_icon.png");
    border: none;
    content: " ";
}

.has-qi {
    span {
        padding-right: 18px;
        position: relative;
        display: block;
        &:after {
            width: 14px;
            height: 14px;
            background-image: url("img/question_icon.png");
            border: none;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -7px;
            content: " ";
        }
    }

}

.paginator-summary {
    display: none;
}