product-filter-category {
  a:hover {
    cursor: pointer;
  }

  .container-menu {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -8px;
  }

  .show-all {
    max-height: none;
  }

  ul.category-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li a {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      display: block;
    }
  }

  .no-children {
    padding: 0.5rem;
    color: $color7;
    text-align: center;
  }

}