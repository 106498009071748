.cart-module {

    header.page-header {
        h1 {
            display: inline-block;
        }
        button.btn {
            vertical-align: super;
            margin: 5px 0;
            margin-left: 5px;
            padding: 5px 10px;
            font-size: 13px;
        }
    }
}
.modal-body {
    h3 {
        margin: 0.3rem 0 2rem;
        font-size: 18px;
        font-weight: bold;
    }
    .import-actions {
        text-align: center;
        margin: 1rem 0;
    }
}
.modal-md {
    width: 700px!important;
}
