.attach-dropbox {
  position: relative;
  min-height: 100px;
  height: 100%;
  text-align: center;
  border: 4px dashed #DDD;
  background: #F8F8F8;
  cursor: pointer;
}

.attachment-table {
  width: 100%;

  tr {
    border: 1px solid #e8e8e8;

    th {
      background: #FAFAFA;
      padding: 8px;

      span {
        margin: 0 0 5px 0;
        font-size: 1.5rem;
        font-weight: 800;
      }
    }

    td {
      padding: 8px;
    }
  }
}

.product-component {
  border: 1px solid $color4;
  border-top: none;
  margin: 0 0 30px;

  .ng-hide.ng-hide-animate {
    display: none !important;
  }

  .step1,
  .step2,
  .step3,
  .step4 {
    border-top: 1px solid $color4;

    & > div:not(:last-of-type) {
      padding: 10px;
    }

    .warranty {
      border-top: 1px solid $color4;
      margin-top: 10px;

      b {
        margin-left: 10px;
      }
    }

    .step-header {
      padding: 8px 10px;
      background: $color3;
      font-size: 1.5rem;
      font-weight: 800;

      &:not(.text-right) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .alert {
      margin: 10px 10px 20px;
    }

    button {
      margin: 10px 10px 10px 0;
    }
  }

  .step2,
  .step3 {
    border-top: none;
  }

  input[type=number] {
    appearance: none;
    -moz-appearance: textfield;
    width: 55px;
    min-width: 55px;
    float: left;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }

    &.invalid {
      border-color: $errorColor;
    }
  }

  .input-number-container {
    display: flex;
  }

  .btn-group-vertical {
    .btn-qty {
      width: 1.7rem;
      height: 1.7rem;
      margin: 0;
      text-align: center;
      padding: 0;
      line-height: 1.7rem;
      border-width: 0!important;
      border-radius: 0!important;
      border-color: $borderColor;
    }
  }

  .error-field {
    border-color: $errorColor;
  }

  .c-error {
    color: $errorColor;
    font-size: 1.2rem;
    margin-top: 0.2rem;
    white-space: nowrap;
  }

  label.required:after {
    position: absolute;
  }
}

.drop-box {
  position: relative;
  min-height: 100px;
  height: 100%;
  text-align: center;
  border: 4px dashed #DDD;
  background: #F8F8F8;
  cursor: pointer;

  .msg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.accept-file {
    border-color: #00AA00;
  }

  &.reject-file {
    border-color: #d80814;
  }

}

.table-attachments {
  margin-bottom: 3rem;
  border: 1px solid $color4;

  &__row {
    transition: all linear 0.35s;

    display: flex;
    align-items: center;

    &.ng-move, &.ng-enter, &.ng-leave.ng-leave-active {
      opacity: 0;
      transform: scale(0);
    }

    &.ng-move.ng-move-active, &.ng-enter.ng-enter-active, &.ng-leave {
      opacity: 1;
      transform: scale(1);
    }

    &--header {
      background: $color3;
      min-height: 45px;
    }

    & + & {
      border-top: 1px solid $color4;
    }
  }

  &__col {
    &--action {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__status {
    margin-right: 3rem;

    &--error {
      font-weight: bold;
      color: red;
    }

    &--success {
      font-weight: bold;
      color: green;
    }
  }

}

.product-table {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 20px;
}

.furniture-table-header {
  border: 1px solid $color4;
  background: #FAFAFA;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.table-header-products {
  border: 1px solid $color4;
  background: #FAFAFA;
  padding: 7px;
  font-size: 1.3rem;
}

.btn-product {
  padding: 3px 9px !important;
}

.furniture-loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spinner {
  height: 120px;
  width: 120px;
  border: 6px solid;
  border-color: black transparent black transparent;
  border-radius: 50%;
  animation: spin 1.3s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
form[name="furnitureRegistration"] .cg-busy-animation {
  top: 50%;
}
