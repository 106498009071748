.order-module {

  header  {
    .row {
      hyphens: auto;
      word-break: break-all;
    }
  }

  .form-group {

    p.form-control-static {
      font-weight: bold;
      padding-top: 5px;
    }
  }

  .order-amounts {
    order-amounts-summary {
      margin-bottom: 50px;
    }
  }

  .shipment-date-container {
    position: relative;
    .address-source-options {
      position: relative;
      bottom: 0;
      right: 0;
      margin-bottom: 15px;
      font-size: 12px;
      a {
        cursor: pointer;
      }
    }
  }
  .complete-order {
    margin: 1.5rem 0;
  }
  h4 {

    text-transform: uppercase;
    font-weight: bold;
    &:not(:first-child()) {
      margin-top: 30px;
    }
  }
  h5 {
    text-transform: uppercase;
    font-weight: bold;
    padding-bottom: 8px;
    border-bottom: 1px solid $color2;
  }
  footer {
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .cod-amount-form-group {
    label {
      padding-left: 15px;
      margin-right: 5px;
      &:after {
        content: ":";
      }
    }
    input {
      width: 150px;
      display: inline-block;
    }
  }
  button.btn-primary {
    font-weight: bold;
    text-transform: uppercase;
  }

  sg-order-summary {
    margin-bottom: 40px;
  }
  .order-step-1 {
    textarea {
      &.form-control {
        height: 106px;
        padding: 12px;
      }
    }
    footer {
      .btn-link {
        margin: 0.7rem 1.3rem;
      }
    }
  }

  .sg-cod-amount {
    label {
      font-size: 1rem;
      padding: 0 !important;
    }
  }
}

.orders-module {
  @extend .order-module;
  .filters {
    @extend %filter-section;
  }
  .orderedItems,
  .orderShipments,
  .orderInvoices {
    th {
      &:first-child {
        width: 50px;
      }
    }
    h4 {
      margin-top: 35px;
      margin-bottom: 15px;
    }
  }
}

.discount-group {
  color: $color7;
}
