product-filter-active {
  .filter-body {
    .container-list, .container-tags {
      margin-top: 0.5rem;
    }

    .container-tags {
      margin-left: -0.5rem;
      margin-right: -0.5rem;

      .tag {
        display: inline-block;
        margin: 0.5rem;
        line-height: 2rem;

        background: $color3;
        border: solid thin $color4;
        border-radius: 3px;

        > span {
          margin-left: 0.5rem;
        }

        .delete {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          cursor: pointer;
          border-radius: 3px;
          background: transparent;

          &:hover {
            color: $color1;
          }
        }

      }
    }
  }

  .filter-footer {
    padding: 0 8px;

    button {
      margin-bottom: 0.5rem;
    }
  }
}