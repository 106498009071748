spinner {
  display: block;
  margin: 60px auto;

  text-align: center;
  font-size: 14px;
  .spinnerWrapper {
    width: 50px;
    height: 40px;
    display: block;
    margin: 15px auto;
    text-align: center;
    & > div {
      background-color: $color2;
      height: 100%;
      width: 6px;
      display: inline-block;
      animation: sk-stretchdelay 1.2s infinite ease-in-out;
    }

    .rect2 {
      animation-delay: -1.1s;
    }
    .rect3 {
      animation-delay: -1.0s;
    }
    .rect4 {
      animation-delay: -0.9s;
    }
    .rect5 {
      animation-delay: -0.8s;
    }
  }
  p {
    display: inline-block;
    margin: 0 auto;
  }

  @keyframes sk-stretchdelay {
    0%, 40%, 100% {
      transform: scaleY(0.4);
    }
    20% {
      transform: scaleY(1.0);
    }
  }

}
