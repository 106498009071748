@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,600&subset=latin-ext';

html {
  font-size: 10px;
  height: 100%;
}

body {
  font-size: 1.3rem;
  color: $color2;
  font-family: 'Open Sans', sans-serif;
  height: 100%;
}
.wrapper {
  min-height: 100%;
  height: auto;
  padding-bottom: 9.5rem;
}

.page-header {
  margin: 1rem 0;
  padding: 0;
  border: none;
  h1 {
    margin: 0.5rem 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0;
}

h1 {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 2.8rem;
}

.breadcrumb {
  background-color: $color3;
  color: $color2;
  font-size: 1.2rem;
  margin: 1.5rem 0 0;
  a {
    color: $color2;
  }
}

.table {
  > thead {
    > tr {
      background-color: $color3;
      border-top: 1px solid $borderColor;
      border-bottom: none;
      &:last-child {
        border-bottom: 1px solid $borderColor;
      }
      > th {
        font-size: 1.3rem;
        border-bottom: none;
        vertical-align: middle;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      &.filters {
        background-color: $color5;
        > th {
          padding-top: 5px;
          padding-bottom: 5px;
          .form-control {
            height: 2.6rem;
            line-height: 2.6rem;
            font-weight: normal;
          }
        }
      }
    }
  }
  > tbody {
    > tr {
      > td {
        background: $color5;
        border-top: 1px solid $borderColor;
        vertical-align: middle;
        &.actions {
          white-space: nowrap;
        }
        .btn {
          padding: 3px 9px;
        }
      }
    }
  }
}

nav.panel {
  border: none;
  box-shadow: none;
  .panel-heading {
    background-color: $color5;
    border: none;
    padding: 15px 0;
    .panel-title {
      color: $color2;
      font-size: 2.4rem;
      font-weight: bold;
    }
  }
  .panel-body {
    padding: 0;
    background: $color3;
    ul {
      > li {
        border-left: 4px solid $borderColor;
        margin-top: 0;
        > a {
          color: $color2;
          border-radius: 0;
        }
        &.active {
          border-left: 4px solid $color1;
          > a {
            color: $color2;
            background-color: $color5;
          }
        }
      }
    }
  }
}

.well {
  border-radius: 0;
  border: none;
  box-shadow: none;
  @extend %filter-section;
}

.navbar-nav {
  > li {
    > .dropdown-menu {
      padding: 0;
      border-radius: 0;
      background: $color3;
      border: 1px solid $borderColor;
      border-left: none;
      box-shadow: none;

      >li {
        border-bottom: 1px solid $borderColor;
        &:last-child {
          border: none;
        }
        > a {
          padding: 1rem;
          border-left: 4px solid $borderColor;
          &.active {
            border-left: 4px solid $color1;
            background: $color5;
          }
        }
      }
    }
  }

}

a {
  @extend %default_link;
}

.container {
  min-width: 1170px !important;
  width: 1170px !important;
}

.pagination {
  > .active {
    > a {
      color: $color2;
      background: $color3;
      border: 1px solid $color1;
      &:focus,
      &:active,
      &:hover {
        color: $color2;
        background: $color3;
        border: 1px solid $color1;
      }
    }
  }
  > li {
    &.disabled {
      display: none;
    }
    > a {
      border-radius: 0 !important;
      margin-left: 0.3rem;
      padding: 4px 8px;
      color: $color2;
      background: $color3;
      border: 1px solid $borderColor;
      &:hover {
        color: $color2;
        background: $color3;
        border: 1px solid $color1;
      }
    }
  }
}


// toaster messages styles
.humane,
.humane-flatty {
  font-family: 'Open Sans', san-serif;
  border: none;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  z-index: 200;
}
.humane.humane-flatty-info,
.humane-flatty.humane-flatty-info {
  background-color: $bgInfoColor;
  color: $infoColor;
}
.humane.humane-flatty-success,
.humane-flatty.humane-flatty-success {
  background-color: $bgSuccesColor;
  color: $successColor;
}
.humane.humane-flatty-error,
.humane-flatty.humane-flatty-error {
  background-color: $bgErrorColor;
  color: $errorColor;
}

.humane.humane-flatty-warning,
.humane-flatty.humane-flatty-warning {
  background-color: #fcf8e3;
  color: $color2;
}

// cg-busy spinner
.cg-busy-default-sign{
  border-radius: 0;
  color: $color2;
  text-shadow: none;
  background-color:transparent;
  border: none;
  box-shadow: none;
}

.popover {
  border-radius: 0;
  //border-color: $errorColor;
}

%filter-section {
  background: $color3;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  label {
    font-size: 1.2rem;
  }
  .form-control {
    height: 28px;
    padding: 6px;
  }
  select {
    &.form-control {
      padding: 0 6px;
    }
  }
  .form-control-feedback {
    height: 28px;
    line-height: 28px;
  }
}

.defaultShipping {
  font-size: 1rem;
  color: $color7;
}

.dropdown-menu {
  border-radius: 0;
  padding: 0;
  border-width: 0; 
  > a {
    padding: 4px 10px;
    border-top: 1px solid $borderColor;
    &:first-child {
      border-top: none;
    }
  }
  > .active {
    > a {
      background: $color2;
      &:hover, &:focus {
        background: $color2;
      }
    }
  }

}
// fix
.modal.center-modal-child.in .modal-dialog {
  transform: translate(-50%, -49%);
}

.word-break {
  word-break: break-word;
}

.clear {
  clear: both;
}