sg-contact-mozaic {
    display: block;
    margin-top: 10px;

    &:first-child {
        margin-top: 0;
    }

    h5:after {
        content: ':';
    }


    .employee {
        span {
            display: block;
        }
    }

}