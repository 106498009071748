datepicker select, datepicker select:focus, datepicker select:hover,
.datepicker select, .datepicker select:focus, .datepicker select:hover,
[datepicker] select, [datepicker] select:focus, [datepicker] select:hover{
    background-color: $color1;
}
._720kb-datepicker-calendar-header:nth-child(odd) {
    background: $color1;
}
._720kb-datepicker-calendar-header:nth-child(even) {
    background: lighten($color1, 10%);
}
._720kb-datepicker-calendar-header a:hover {
    color:rgba(0, 0, 0, 0.9);
    background: rgba($color1, 0.45);
}
._720kb-datepicker-calendar-days-header{
    background: rgba($color1, 0.08);
}
._720kb-datepicker-calendar-header a, ._720kb-datepicker-calendar-header a:hover {
    color: rgba($color3, 0.7);
}

._720kb-datepicker-calendar-month span {
    color:rgba($color3, 0.7);
}