sg-account-address-selection-table {
    display: block;
    margin-bottom: 20px;
    .table {
        margin-bottom: 0;

        th.column-building {
            width: 80px;
        }

        th.column-postcode {
            width: 110px;
        }

        th.column-city {
            width: 180px;
        }

    }
}