product-filter-price {
  .filter-header {
    cursor: pointer;
  }
  .filter-body {
    .filter-price {
      padding-top: 1rem;

      >div {
        display: inline-block;
        vertical-align: top;
        &.price_form_to {
          width: 20px;
        }
        &.price_form_to_input {
          width: 98px;
        }
      }
    }
    margin-bottom: 1em;

  }
  .filter-footer {
    padding: 0 8px;
  }
  .invalid {
    border-color: $errorColor;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}