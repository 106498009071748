active-cart-widget {
    float: right;
    display: inline-block;
    min-height: 45px;
    max-width: 220px;

    .main-container {
        padding: 2px 10px;
        background-color: #FFF;
        outline: none;
        border: solid 1px rgba($color6, 0);
        transition: border .3s ease-in-out;
        width: 220px;

        //Scale icon animation when mouse rollover
        &:not([disabled]):hover {
            border: solid 1px rgba($color6, 0.3);
            .cart-icon:before {
                transform: scale(1.1);
            }
        }

        /* Add a cart icon to the left side of the element */
        .with-cart-icon {
            padding-left: 40px;
            position: relative;
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                margin: -2px -8px 0 -8px;
                height: 39px;
                width: 46px;
                display: block;
                content: ' ';
                background-image: url('img/cart_icon.png');
                background-position-y: 51px;
                background-position-x: 85px;
                transition: transform .15s ease-in-out, filter .5s ease-in-out;
            }
        }

        /* Information of the current active cart */
        .cart-active {
            margin: 4px 0;
            .cart-name {
                display: block;
                max-width: 160px;
                font-weight: bold;
                line-height: 18px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .cart-amount {
                margin-left: 5px;
            }
        }

        /* "No active cart" message */
        .no-cart-active {
            margin: 4px 0;
            height: 35px;
            display: flex;
            align-items: center;
            &:before {
                filter: grayscale(85%);
            }
            &:hover:before {
                filter: grayscale(0);
            }
        }

    }

}