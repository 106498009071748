product-info-delivery {
  button.btn-info-small {
    padding: 3px 9px;

    &, &:active {
      width: 18.5px;
      height: 18.5px;
      background-image: url("img/calendar_icon.png");
      background-position-x: 19.7px;
      background-position-y: 50px;
      border: none;
      content: " ";
      transition:none;
      filter: grayscale(100%);
    }
    &:hover {
      filter: grayscale(0);
    }
  }
}