.product-filter-container {

  .no-active-filters {
    padding: 0.5rem;
    color: $color7;
    text-align: center;
  }

  div.filter-buttons {
    margin-top: 1rem;

    .line{
      border-top: solid 1px $borderColor;
      margin-bottom: 1rem;
    }

    .buttons {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .price_form_to {
    padding-top: 8px;
    padding-bottom: 6px;
  }

  .arrow-after-up:after {
    right: 1rem;
  }

  .arrow-after-down:after {
    right: 1rem;
  }

}