sg-cart-items {
  display: inline-block;
  padding-bottom: 1rem;
  .cg-busy{
    top: 50px;
    bottom: 50px;
    z-index: 100;
  }
  thead {
    position:relative;

    tr.fixed {
      position: fixed;
      display:none;
      top: 0;
      z-index: 101;
    }
    tr.loader {
      background: none;
      border-top: none;
      border-bottom: none !important;
    }
    &.fixed {
      tr.fixed {
        display:block;
      }
    }
    th {
      box-sizing: border-box;
    }
    th.id-column {
      width: 38px;
      min-width: 38px;
    }
    th.select-column {
      width: 30px;
      padding: 8px 0;
    }
    th.sku-column {
      width: 90px;
      min-width: 90px;
    }
    th.price-column {
      width: 107px;
      min-width: 107px;
    }
    th.name-column {
      width: 100%;
    }
    th.qty-column {
      width: 90px;
      min-width: 90px;
    }
    th.warehouse-column {
      width: 60px;
      min-width: 60px;
    }
    th.availability-column {
      width: 95px;
      min-width: 95px;
    }
    th.discount-column {
      width: 68px;
      min-width: 68px;
      white-space:nowrap;
    }
    th.remove-column {
      width: 110px;
      min-width: 110px;
      text-align: center;
      padding: 0;
    }
  }
  tbody {
    tr {
      span.price {
        display: block;
        &.price-deprecated {
          text-decoration: line-through;
          color: $color7;
        }
      }
      td {
        input[type=checkbox] + label {
          margin-top: 5px;
        }
        input[type=text] {
          width: 80px;
          min-width:80px;
          padding-left: 6px;
          padding-right: 6px;
        }
        input[type=number] {
          width: 55px;
          min-width:55px;
          padding-left: 3px;
          padding-right: 3px;
        }
        &.remove-column {
          padding: 0;
        }
        &.select-column {
          padding: 8px 0;
        }
      }

      .promotion-info,
      .cart-item-name {
        //font-size: 1.2rem;
      }
      &.add-new-item {
        td {
          background: $color3;
          border-bottom: 1px solid $borderColor;
          z-index: 101;
        }
      }
    }
  }
}
