.account-module {

    h5 {
        font-weight: bold;
    }

    .info-block {
        margin-bottom: 3rem;

        h5:after {
            content: ':';
        }

        ul {
            padding-left: 0;
            list-style: none;
        }

        address {
            margin-bottom: 1rem;
        }
        .recipient {
            span {
                &:not(:last-child) {
                    &:after {
                        content: ", ";
                    }
                }
            }
        }
    }

    .table {
        th.column-building {
            width: 80px;
        }

        th.column-postcode {
            width: 110px;
        }

        th.column-city {
            width: 180px;
        }
    }

    .roles {
        background: $color3;
        padding: 1rem 2rem;
        h2 {
            font-size: 1.8rem;
            font-weight: bold;
        }
        h3 {
            font-size: 1.4rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        .role {
            padding-bottom: 1rem;
            font-size: 1.3rem;
        }
    }

    .mailer-allow {
        border-top: 1px solid $borderColor;
        padding-top: 2rem;
        margin-top: 1rem;
    }
}