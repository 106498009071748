$color1: #ff6d00; // active color
$color1Hover: #ff9f00;
$color2: #4a5456; // font, dark bg color

$color3: #fafafa; // light bg color
$color4: #e8e8e8; // border color
$color5: #fff;
$color6: #c1c4c5; // focused border;
$color7: #A3A3A3; // grey text
$color8: #AFCF52; // Light green

$color-blue: #00018a;

$successColor: #3c763d;
$bgSuccesColor: #d6e9c6;
$errorColor: #a94446;
$bgErrorColor: #f2dede;
$infoColor: #31708f;
$bgInfoColor: #d9edf7;

$borderColor: $color4;
$fBorderColor: $color6;

$color9: #242424;
$color10: #ffb400;
