product-related-button-launcher {
    button.btn-info-small {
        &, &:active {
            width: 18.5px;
            height: 18.5px;
            background-image: url("img/related_icon.png");
            background-position-x: 37.5px;
            background-position-y: 31.9px;
            border: none;
            content: " ";
            transition:none;
            filter: grayscale(100%);
        }
        &:hover {
            filter: grayscale(0);
        }
    }
}