product-availability-icon {
    display: inline-block;

    div.icon-container {
        display: inline-block;

        circle {
            fill: $color2;
            &.available {
                fill: $color8;
            }
            &.onorder {
                fill: $color10;
            }
            &.unavailable {
                fill: $errorColor;
            }
        }

    }

}