sg-offer-item,
[sg-offer-item],
sg-offer-item-extended,
[sg-offer-item-extended] {
    td.remove-column {
        text-align: center;
    }
    td.percentage-column {
        .discount-group {
            color: $color7;
            &.deprecated {
                text-decoration: line-through;
            }
        }
        input {
            padding-left: 2px;
            padding-right: 2px;
        }
    }
    input[type=checkbox] {
        &:focus {
            outline: none;
        }
    }
    input[type=number] {
        appearance: none;
        -moz-appearance: textfield;
        width: 55px;
        min-width: 55px;
        float: left;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
        &.invalid {
            border-color: $errorColor;
        }
    }
    .input-number-container {
        display: table;
    }
    .btn-group-vertical {
        .btn-qty {
            width: 1.7rem;
            height: 1.7rem;
            margin: 0;
            text-align: center;
            padding: 0;
            line-height: 1.7rem;
            border-width: 0!important;
            border-radius: 0!important;
            border-color: $borderColor;
        }
    }
    input[readonly] {
        background-color: inherit;
        border: none;
    }
    .promotion-info {
        color: $color7;
    }

    .badge {
        cursor: default;
        padding: 6px 10px;
        transition: background-color .2s linear;
    }
    .badge.badge-success {
        background-color: $successColor;
    }
    .badge.badge-warning {
        background-color: $errorColor;
    }

    confirmable {
        button {
            transition: width ease-out 0.2s;
            width: 70px;
            text-align: center;
        }
        &[confirmable-state="confirming"] {
            button {
                width: 110px;
            }
        }
    }

    .input-group-addon {
        width: auto;
        padding-left: 5px;
        padding-right: 5px;
    }


    .flex-item {
        display: flex;
        align-items: center;
        justify-items: space-around;
    }

    .warning-price {
        position: relative;
        color: $errorColor;
        margin-left: 15px;

        &:after {
            content: '';
            position: absolute;
            transform: translateX(-60%);
            height: 20px;
            width: 20px;
            border: 1px solid $errorColor;
            border-radius: 50%;
        }
    }

    input.required-empty {
        border: 1px solid $errorColor;
    }
}
