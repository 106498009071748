sg-product-quick-info-item,
[sg-product-quick-info-item] {
    td.percentage-column {
        .discount-group {
            color: $color7;
            &.deprecated {
                text-decoration: line-through;
            }
        }
        input {
            padding-left: 2px;
            padding-right: 2px;
        }
    }
    input[type=number] {
        appearance: none;
        -moz-appearance: textfield;
        width: 55px;
        min-width: 55px;
        float: left;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }
        &.invalid {
            border-color: $errorColor;
        }
    }
    .input-number-container {
        display: table;
    }
    .btn-group-vertical {
        .btn-qty {
            width: 1.7rem;
            height: 1.7rem;
            margin: 0;
            text-align: center;
            padding: 0;
            line-height: 1.7rem;
            border-width: 0!important;
            border-radius: 0!important;
            border-color: $borderColor;
        }
    }
    input[readonly] {
        background-color: inherit;
        border: none;
    }

    .badge {
        cursor: default;
        padding: 6px 10px;
        transition: background-color .2s linear;
    }
    .badge.badge-success {
        background-color: $successColor;
    }


    .badge.badge-warning {
        background-color: $errorColor;
    }

    confirmable {
        button {
            transition: width ease-out 0.2s;
            width: 70px;
            text-align: center;
        }
        &[confirmable-state="confirming"] {
            button {
                width: 110px;
            }
        }
    }

    .flex-item {
        display: flex;
        align-items: center;
        justify-items: space-around;
    }
}
