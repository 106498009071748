sg-cart-editor {

    header {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .name-container {
            width: 300px;
            h4 {
                margin-bottom: 22px;
            }
        }
        sg-cart-actions-bar {
            margin-bottom: 16px;
        }
    }
    footer {
        margin-top: 10px;

        .cart-summary {
            display: flex;
            align-items: flex-end;
            >div {
                flex: 1;
            }
        }

        button {
            margin-top: 30px;
        }

    }

}