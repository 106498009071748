[sticky-table-header] {
	position: relative;

	.sticky-clone {
		display: table;
		position: absolute;
		left: 0;
		transform: translate3d(-100000px, 0, 0);
		z-index: 1000;

		&.sticky-stuck {
			transform: translate3d(0, 0, 0);
		}
	}
}