sg-header {
  header {
    position: relative;
    z-index: 75;
    margin-bottom: 0;
    background: $color5;
    border: none;
    .header1 {
      position: relative;
      z-index: 100;
      background: $color3;
      border-bottom: 1px solid $borderColor;
      .navbar-right {
        margin-right: 0;
      }
      .navbar-nav {
        > li {
          &.logged-user-name {
            border-right: 1px solid $borderColor;
            > a {
              text-overflow: ellipsis;
              white-space: nowrap;
              //max-width: 730px;
              overflow: hidden;
            }
          }
          > a {
            padding: 0 1rem;
            color: $color2;
            &.arrow-after-down {
              padding-right: 2.5rem;
            }
            height: 3rem;
            line-height: 3rem;
            font-size: 1.3rem;
          }
          > ul {
            a {
              color: $color2;
            }
          }
          &.open {
            > a {
              &:after {
                border-width: 2px 0 0 2px;
                margin-top: -0.2rem;
              }
            }
          }
        }
      }
    }
    .header2 {
      margin-top: 1.5rem;
      .navbar-brand {
        height: auto;
        padding: 0;
      }
      .helpline {
        text-align: right;
        font-size: 1.8rem;
        line-height: 5rem;
      }

    }
    .main-menu {
      > nav {
        margin-top: 1.5rem;
        position: relative;

        > .nav-border {
          position: absolute;
          z-index: 0;

          top: 0;
          left: 15px;
          right: 15px;
          bottom: 0;

          border: 1px solid $borderColor;
        }

        > .navbar-nav {
          position: relative;
          z-index: 75;

          //border: 1px solid $borderColor;
          float: none;
          > li {
            > a {
              border-top: 1px solid $borderColor;
              border-bottom: 1px solid $borderColor;
            }

            &:hover {
              > a {
                border-top: 1px solid $borderColor;
                border-bottom: 1px solid $borderColor;
              }
            }
          }

          li:first-child > a:focus {
            border-left: solid $borderColor 1px;
          }

          // todo: refactor
          a, a:hover, a:active, a:focus {
            border-color: $borderColor;
          }

          li {
            float: left;
            text-align: center;
            &.products {
              width: 17%;

              > a {
                border-right: 1px solid $borderColor;
              }

              .category-products-list {
                width: 100%;
                display: none;
                position: absolute;
                margin-top: -1px;

                & > .dropdown-menu {
                  margin: 0;
                  display: block;
                  box-shadow: none;
                }

                & > ul.top-category {
                  width: 100%;
                  position: relative;
                  z-index: 75;

                  & > li:first-child > .title {
                    border-top: 1px solid $borderColor;
                  }

                  & > li {

                    &:last-of-type {
                      &.small-subcategory {
                        .subcategory-tab {
                          bottom: 0;
                        }
                      }
                    }

                    & > .title {
                      position: relative;
                      z-index: 100;

                      display: block;

                      border-bottom: 1px solid $borderColor;
                      border-left: 1px solid $borderColor;
                      border-right: 1px solid $borderColor;

                      padding: 0.8rem 1.4rem;
                    }

                    & > .all-products {
                      font-style: italic;
                      text-align: center;
                    }

                    & > a {
                      &:hover {
                        color: $color1;
                      }
                    }

                    &.small-subcategory {
                      position: relative;
                      .subcategory-tab {
                        top: inherit;
                        bottom: -37px;
                      }
                    }

                    .subcategory-tab {
                      position: absolute;
                      left: 100%;
                      top: 0;
                      margin-left: -1px;
                      width: 856px;
                      display: none;

                      z-index: 50;

                      background: $color5;
                      border: solid 1px $borderColor;

                      .border-left {
                        border-left: solid 1px $borderColor;
                      }

                      .recommends-box {
                        height: 100%;
                      }

                      .subcategory-body {
                        padding-right: 15px;
                        padding-left: 15px;
                        padding-bottom: 15px;

                        .see-all {
                          padding: 0;
                          &:hover {
                            background: transparent;
                            color: $color1;
                          }
                        }

                        & > .row {
                          width: 100%;
                          margin-right: 0;
                          margin-left: 0;
                        }

                        .title-subcategory {
                          display: block;
                        }

                        .recommended-in-subcategory {
                          display: block;
                        }

                        .subcategory-container-list {
                          overflow: auto;
                          display: block;
                        }

                        .div-recommended-container-list {
                          overflow: auto;
                          display: block;
                        }

                        ul.subcategory-list {
                          margin: 0;
                          padding: 0;

                          &.multi-column {
                            column-count: 2;
                          }

                          a {
                            display: block;
                            padding-left: 0;

                            &:hover {
                              background: transparent;
                              color: $color1;
                              cursor: pointer;
                            }
                          }
                        }

                        ul.subcategory-product-list {
                          margin: 0;
                          padding: 0;

                          li {
                            border-bottom: solid 1px $borderColor;
                            padding-bottom: 1rem;
                            margin-bottom: 1rem;

                            .name {
                            }
                            .price {
                              margin-top: 1rem;
                              margin-bottom: 1rem;
                            }
                            .buttons {
                            }
                            .discount-group {
                              color: $color7;
                            }
                          }

                          li:last-child {
                            border-bottom: none;
                          }
                        }

                        .h2 {
                          font-size: 2rem;
                          border-bottom: solid 1px $borderColor;
                          padding-bottom: 1.5rem;

                          .title {
                            margin-right: 1.5rem;
                          }
                        }

                        .recommended-buttons {
                          float: right;
                        }
                      }
                    }

                    &:hover {
                      .subcategory-tab {
                        display: block;
                      }

                      & > .title {
                        border-right: none;
                        background: $color5;
                      }
                      & > .all-products {
                        border-right: 1px solid $borderColor;
                        cursor: pointer;
                      }
                    }

                    .row {
                      display: table;
                    }
                    .row .no-float {
                      display: table-cell;
                      float: none;
                    }
                  }
                }
              }
            }

            a {
              color: $color2;
              text-transform: uppercase;
              font-size: 1.4rem;
              font-weight: bold;
              padding: 0.8rem 1.4rem;
              &:hover {
                background-color: $color3;
              }
              &.active {
                background-color: $color5;
                color: $color1;
                &:hover {
                  background-color: $color3;
                }
              }
            }
            &.dropdown {
              > a {
                padding-right: 2.5rem;
                background-color: transparent;
                &:focus {
                  background-color: $color3;
                }
                &.arrow-after-down:after {
                  margin-top: -0.6rem;
                }
              }
              li {
                display: block;
                float: none;
                text-align: left;
                a {
                  text-transform: none;
                  font-weight: normal;
                  &:hover {
                    background-color: #f5f5f5;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .header-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;

    background: #ffffff;
    opacity: 0.8;

    display: none;
  }
}

.uib-dropdown-open {
  #category-products-list {
    display: block;
  }
  .header-overlay {
    display: block;
  }
}

.notification {
  display: block;
  width: 100%;

  overflow: hidden;

  background: $color-blue;
  color: white;
  border-bottom: 1px solid $borderColor;
  font-weight: bold;

  padding: 1rem 0;

  &.ng-enter, &.ng-leave {
    transition: height ease-in-out .5s;
  }

  &.ng-enter, &.ng-leave.ng-leave-active {
    height: 0;
  }

  &.ng-leave, &.ng-enter.ng-enter-active {
    height: 4rem;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    font-size: 14px;
    font-height: 2rem;

    white-space: nowrap;
    overflow: hidden;

    &::before {
      display: none;
    }

    &::after {
      content: none;
    }

    .notification-container-after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      height: 100%;
      width: 50px;
      background: linear-gradient(to right, rgba($color-blue, 0), rgba($color-blue, 1) 50%);
      z-index: 1;

      flex: 0 0 auto;
    }
  }

  &__close {
    flex: 0 0 auto;
    
    z-index: 2;

    &:hover, &:focus {
      text-decoration: none;
    }

    &::before {
      height: 1.5rem;
      line-height: 0;
      font-size: 1.5rem;

      margin-right: 1rem;
      content: "\2715";
    }
  }

  &__footer {
    background: $color1;
    color: $color9;
    border-bottom: none;
    font-weight: normal;

    .container {
      font-size: 1.2rem;

      &::after {
        background: none !important;
      }

      a {
        color: $color3;
      }
    }
  }
}
