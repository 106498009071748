sg-offer-options-form {

    h4 {
        display: inline-block;
        margin-top: 6px;
        small {
            font-size: 12px;
        }
    }

    .form-inline {
        margin-top: 18px;
        margin-bottom: 10px;

        .form-group {
            margin-right: 25px;
        }
    }
}