.articles-module {
  h1 {
    margin-bottom: 0.5rem;
  }
  time {
    display: block;
    color: $color7;
    font-size: 1.2rem;
  }
  .content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}