sg-account-balance {
    >div {
        border: solid $borderColor 1px;
        padding: 5px;
    }

    >.row>div:last-child {
        border-left: solid $borderColor 1px;
    }
    .account-balance-item {
        margin-top: 5px;
        margin-bottom: 5px;
        .item-value {
            text-align: right;
            font-weight: bold;
        }
    }
}