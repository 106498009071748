form[name='form'] {
  .control-label{
    font-size: 1.5rem;
  }
  .recover-label {
    font-size: 1.9rem;
    margin-top: 5px;
  }
  .recover-box {
    height: 60px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
sg-login-form,
form[name='recover_pass_form'] {
  .remember-password-link {
    line-height: 3rem;
    padding-top: 1rem;
  }
    a {
      color: #ff6d00;
    }
    a:hover {
      color: #ff9f00;
    }
  //Show the validation msg errors only if the input is mark as invalid
  .form-group .help-block {
    display: none;
  }
  .form-group.has-error .help-block {
    display: block;
  }

  //Message container with the authentication error returned by the server
  .alert {
    margin-bottom: 0;
  }
}

.loginPage {
  margin-top: 1.5rem;
  border-top: 1px solid $borderColor;
  .page-header {
    margin: 7rem 0 3rem;
    h1 {
      font-weight: normal
    }
  }
  .buttons {
    margin-top: 2rem;
  }
  .panel {
    padding-top: 6rem;
    padding-bottom: 6rem;
    border-radius: 0;
    background: $color3;
    border: 1px solid $borderColor;
    box-shadow: none;
    margin-bottom: 6rem;
  }
  .login-links {
    li {
      list-style: none;
      margin: 6rem 0 6rem 3rem;

      a {
        color: $color2;
        position: relative;
        padding-left: 70px;
        &:before {
          margin-top: -25px;
          position: absolute;

          width: 50px;
          height: 50px;
          left: 0;
          top: 50%;
          content: " ";
        }
        &.stalgast {
          &:before {
            background: url('img/stalgast_icon.png') no-repeat;
            height:51px;
            width: 51px;
          }
        }
        &.catalog {
          &:before {
            background: url('img/catalog_icon.png') no-repeat;
            height:51px;
            width: 51px;
          }
        }
        &.contact {
          &:before {
            background: url('img/contact_icon.png') no-repeat;
            height:51px;
            width: 51px;
          }
        }
      }
    }
  }
  .well {
    padding: 3.5rem
  }
}

.agreement-readmore{
  padding-left: 2rem;
}