.offer-module {

    header.page-header {
        h1 {
            display: inline-block;
            small {
                display: inline-block;
                font-size: 12px;
                padding: 0 1rem
            }
        }
        button.btn {
            vertical-align: super;
            margin: 5px 0;
            padding: 5px 10px;
            font-size: 13px;
        }
    }

}